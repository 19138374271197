// Package Block
import { useState, useEffect } from "react";
import { Modal} from "antd";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { navigateToGoogle } from "../../pages/constant/utils";

const AgeConfirmationModal = ({ ageConfirmationCheck, onOk , settingData  }) => {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Modal
      className="age-restriction-modal"
      open={ageConfirmationCheck}
      onOk={onOk}
      footer={null}
      closable={false}
      centered
    >
      <div className="main-modal">
        {!isLoading &&  <img className="main-logo" src={settingData?.logo}/>}
        <div className="modal-heading">Age Verification</div>
        <p>
          This Website offers tobacco products and is strictly for adult use
          only. Before you proceed further, please confirm that you are 21 years
          of age or older.
        </p>
        <div style={{ display: "flex" }}>
        <button  
          className="age-verify-button cursor-pointer age-verify-color"
          onClick={onOk} >
         21+ Enter
        </button>
        <button className="age-verify-button  cursor-pointer" onClick={navigateToGoogle}>
         Under 21
        </button>
        </div>
      </div>
    </Modal>
  );
};

AgeConfirmationModal.propTypes = {
  settingData: PropTypes.object,
};
const mapStateToProps = (state) => {
  return {
    settingData: state.settingData.ecom.basic,
  };
};
export default connect(mapStateToProps)(AgeConfirmationModal);
