import axios from "axios";
// Config Block
import { ORDER_URL, GET_STATE_URL, GET_CITY_URL } from "../../constants/config";
import { bulkAddToCart, deleteAllFromCartViaApi } from "./cartActions";

// Constant Types Block
import {
  FETCH_ALL_ORDER_SUCCESS,
  FETCH_BILLING_STATES_SUCCESS,
  SALE_ORDER_SUCCESS,
  FETCH_STATES_SUCCESS,
  FETCH_BILLING_CITIES_SUCCESS,
  UPDATE_CUSTOMER_SHIPPING_BILLING,
} from "../constant_types";
import {
  FETCH_FORM_DATA,
  FETCH_CITIES_SUCCESS,
  FETCH_SINGLE_ORDER_SUCCESS,
  FETCH_LOADING_SUCCESS,
  COPY_ORDER_SUCCESS,
  SET_FORM_DATA,
} from "../constant_types";
import {
  UPDATE_CUSTOMER_SUCCESS,
  DELETE_ALL_FROM_CART,
} from "../constant_types";
const fetchAllOrderSuccess = (order) => ({
  type: FETCH_ALL_ORDER_SUCCESS,
  payload: order,
});

const saleOrderSuccess = (order) => ({
  type: SALE_ORDER_SUCCESS,
  payload: order,
});

const fetchSingleOrderSuccess = (order) => ({
  type: FETCH_SINGLE_ORDER_SUCCESS,
  payload: order,
});

const fetchStateSuccess = (states) => ({
  type: FETCH_STATES_SUCCESS,
  payload: states,
});

const fetchBillingStatesSuccess = (states) => ({
  type: FETCH_BILLING_STATES_SUCCESS,
  payload: states,
});
const fetchBillingCitiesSuccess = (cities) => ({
  type: FETCH_BILLING_CITIES_SUCCESS,
  payload: cities,
});
const fetchFormvalues = (order) => ({
  type: FETCH_FORM_DATA,
  payload: order,
});

const setCheckoutValues = (order) => ({
  type: SET_FORM_DATA,
  payload: order,
});

const fetchCitiesSuccess = (cities) => ({
  type: FETCH_CITIES_SUCCESS,
  payload: cities,
});
const changeLoadingSuccess = (bool) => ({
  type: FETCH_LOADING_SUCCESS,
  payload: bool,
});

const copyOrderSuccess = (order) => ({
  type: COPY_ORDER_SUCCESS,
  payload: order,
});

// fetch all orders
export const fetchAllOrders = (params = {}, userDetail, setLoading) => {
  return (dispatch) => {
    axios
      .get(`${ORDER_URL}/list2`, { params })
      .then((res) => {
        dispatch(fetchAllOrderSuccess(res.data));
        setLoading(false);
      })
      .catch((err) => {});
  };
};

// for calculate the grand total
export const getSaleCalculation = (payload) => {
  let authToken = JSON.parse(localStorage.getItem("user")).access_token;
  return axios.post(
    `${ORDER_URL}/calc-charges`,
    { ...payload },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    }
  );
};
// create sale order
export const createSaleOrder = (
  payload,
  userDetail,
  history,
  addToast,
  setDisabled,
  actionsLoading,
  currency
) => {
  return (dispatch) => {
    axios
      .post(`${ORDER_URL}`, { ...payload })
      .then((res) => {
        dispatch(saleOrderSuccess(res.data));
        dispatch(deleteAllFromCartViaApi(null, currency?.channel_id || ""));

        let customer = {
          customer: {
            ...res.data.entity.customer,
          },
          access_token: userDetail.authToken,
          refresh_token: userDetail.refreshToken,
        };

        localStorage.setItem("user", JSON.stringify(customer));
        const {customer_shipping_details, customer_billing_details} = res.data.entity.customer
        dispatch({
          type: UPDATE_CUSTOMER_SHIPPING_BILLING,
          payload: {customer_shipping_details, customer_billing_details},
        });

        history.push(`/thank-you`);
        if (addToast) {
          addToast("Your Order has been Placed Successfully!", {
            appearance: "success",
            autoDismiss: true,
          });
        }
        setDisabled(false);
      })
      .catch((err) => {
        if (addToast) {
          err.response.data.errors.map((error) => {
            addToast(error, {
              appearance: "error",
              autoDismiss: true,
            });
          });
        }
        actionsLoading(false);
        setDisabled(false);
      });
  };
};

// fetch Order By Id
export const fetchOrderById = (id, userDetail, setLoading, history) => {
  return (dispatch) => {
    axios
      .get(`${ORDER_URL}/${id}`)
      .then((res) => {
        setLoading && setLoading(true);
        dispatch(fetchSingleOrderSuccess(res.data.entity));
        setLoading && setLoading(false);
      })
      .catch((err) => {
        history && history.goBack();
      });
  };
};

// fetch states
export const fetchStates = (params) => {
  return (dispatch) => {
    axios
      .get(`${GET_STATE_URL}`, { params: params })
      .then((res) => {
        dispatch(fetchStateSuccess(res.data.entity));
      })
      .catch((err) => {});
  };
};

// fetch billing states
export const fetchBillingStates = (params) => {
  return (dispatch) => {
    axios
      .get(`${GET_STATE_URL}`, { params: params })
      .then((res) => {
        dispatch(fetchBillingStatesSuccess(res.data.entity));
      })
      .catch((err) => {});
  };
};

// fetch billing states
export const fetchBillingCities = (params) => {
  return (dispatch) => {
    axios
      .get(`${GET_CITY_URL}`, { params: params })
      .then((res) => {
        dispatch(fetchBillingCitiesSuccess(res.data.entity));
      })
      .catch((err) => {});
  };
};

// fetch states
export const fetchFormValue = (formData) => {
  return (dispatch) => {
    dispatch(fetchFormvalues(formData));
  };
};

// set Form Values
export const setFormValue = (payload) => {
  return (dispatch) => {
    dispatch(setCheckoutValues(payload));
  };
};

// fetch states
export const fetchCities = (params) => {
  return (dispatch) => {
    axios
      .get(`${GET_CITY_URL}`, { params: params })
      .then((res) => {
        dispatch(fetchCitiesSuccess(res.data.entity));
      })
      .catch((err) => {});
  };
};
// for loading change for quick change
export const JreRenderList = () => {
  return (dispatch) => {
    dispatch(changeLoadingSuccess(true));
    setTimeout(() => {
      dispatch(changeLoadingSuccess(false));
    }, 0);
  };
};

export const copyOrder = (id, params, setLoading, history) => {
  return (dispatch) => {
    axios
      .get(`${ORDER_URL}/${id}`, { params })
      .then((res) => {
        dispatch(copyOrderSuccess(res.data.entity));
        const items = res.data.entity.sale_order_details.map((item) => {
          return {
            ...item.product,
            enterQuantity: item.qty,
            selected_unit_price: { price: item.unit_price, unit: item.unit },
          };
        });

        dispatch(bulkAddToCart(items, res.data.entity?.channel?.id));
        setLoading && setLoading(false);
        history && history.push(`/cart`);
      })
      .catch((err) => {
        history && history.goBack();
      });
  };
};
