import React, { useEffect, useRef, useState } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { logoutWithWithNormal } from "../../../redux/actions/loginActions";
import { getMenuItems } from "../../utils/menuItems";
import { handleUserLogout } from "../../utils/authUtils";

import HeaderOne from '../../../wrappers/header/HeaderOne';
import HeaderTemplate1 from './template1';
import HeaderTemplate2 from './template2';
import HeaderTemplate3 from './template3';
import HeaderTemplate4 from './template4';

// eslint-disable-next-line no-extend-native
String.prototype.capitalize = function() {
  return this
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const Header = ({ settingData, themeSettingData, categoryTreeData, templateStyles, brandsData }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation()
  const { currentState, cartData, currency } = useSelector(
    (state) => ({
      currentState: state.userData,
      cartData: state.cartData,
      currency: state.settingData?.["admin-panel"]?.basic || {},
    }),
    shallowEqual
  );

  const canonicalUrl = window.location.href.split('?')[0];
  useEffect(() => {
    let linkTag = document.querySelector('link[rel="canonical"]');
    if (!linkTag) {
      linkTag = document.createElement("link");
      linkTag.setAttribute("rel", "canonical");
      document.head.appendChild(linkTag);
    }
    linkTag.setAttribute("href", canonicalUrl);
  }, [location]);

  const { logout } = currentState;
  if (logout) {
    history.push(`/login`);
    dispatch(logoutWithWithNormal(false));
  }

  const profileItems = getMenuItems(history, location, () => handleUserLogout(history));

  const templates = {
    'default': HeaderOne,
    '1': HeaderTemplate1,
    '2': HeaderTemplate2,
    '3': HeaderTemplate3,
    '4': HeaderTemplate4,
  };

  const skeleton = () => (
    <div className="tw-flex tw-items-center tw-p-4 tw-bg-gray-100 tw-animate-pulse tw-space-x-4 tw-absolute tw-w-full tw-top-0 tw-h-28">
      <div className="tw-w-24 tw-h-8 tw-bg-gray-300 tw-rounded"></div>
      <div className="tw-flex-1 tw-h-8 tw-bg-gray-300 tw-rounded"></div>
      <div className="tw-flex tw-space-x-4">
        <div className="tw-w-8 tw-h-8 tw-bg-gray-300 tw-rounded"></div>
        <div className="tw-w-8 tw-h-8 tw-bg-gray-300 tw-rounded"></div>
        <div className="tw-w-8 tw-h-8 tw-bg-gray-300 tw-rounded"></div>
      </div>
    </div>
  );
  
  const navTemplate = templateStyles?.nav_template || themeSettingData?.nav_template || 'default';
  const SelectedTemplate = templates[navTemplate] || skeleton;

  const headerRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    if (!headerRef.current) return;

    const header = headerRef.current?.querySelector(".header-tmpl");
    if (!header) return;

    const updateHeaderState = () => {
      const rect = header.getBoundingClientRect();
      setHeaderHeight(rect.height);

      setIsSticky(window.scrollY >= headerRef?.current?.offsetTop);
    };

    window.addEventListener("scroll", updateHeaderState);
    updateHeaderState();

    const handleResize = () => {
      window.requestAnimationFrame(updateHeaderState);
    };

    // MutationObserver to detect CSS changes or any style-related modifications
    const observeCSSChanges = () => {
      const observer = new MutationObserver(() => {
        updateHeaderState();
      });

      observer.observe(document.documentElement, {
        attributes: true,
        attributeFilter: ["style", "class"],
        subtree: true,
      });

      return observer;
    };

    // ResizeObserver for resizing
    const resizeObserver = new ResizeObserver(handleResize);

    if (headerRef.current) {
      resizeObserver.observe(headerRef.current);
    }

    const mutationObserver = observeCSSChanges();

    window.addEventListener("resize", handleResize);

    return () => {
      resizeObserver.disconnect();
      mutationObserver.disconnect();
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", updateHeaderState);
    };
  }, [SelectedTemplate, navTemplate, settingData, themeSettingData]);

  return (
    <div
      style={{
        height: headerHeight,
      }}
      ref={headerRef}
    >
      {SelectedTemplate ? (
        <div
          style={{
            position: isSticky ? "fixed" : "relative",
            top: isSticky ? 0 : "auto",
            left: 0,
            width: "100%",
            background: "white",
            zIndex: 1000,
            transition: "top 0.3s ease",
            boxShadow: isSticky ? "0px 4px 6px rgba(0, 0, 0, 0.1)" : "none",
          }}
        >{isSticky}
          <SelectedTemplate
            settingData={settingData}
            themeSettingData={themeSettingData}
            categoryTreeData={categoryTreeData}
            brandsData={brandsData}
            currentState={currentState}
            profileItems={profileItems}
            cartData={cartData}
            currency={currency}
          />
        </div>
      ) : (
        skeleton
      )}
    </div>
  );
};

Header.propTypes = {
  settingData: PropTypes.object,
  themeSettingData: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    settingData: state.settingData.ecom.basic,
    themeSettingData: state.settingData.ecom.theme,
    categoryTreeData: state.categoryData.entities,
    brandsData: state.brandsData,
  };
};

export default connect(mapStateToProps)(Header);
