import React, { createContext, useContext, useState } from "react";
import { searchFilter, setSearchTagKeys } from "./pages/constant/Constants";

const MainUIContext = createContext();

export function useMainUIContext() {
  return useContext(MainUIContext); 
}

export const MainUIConsumer = MainUIContext.Consumer;

export function MainUIProvider({ children }) {
  const [searchKey, setSearchKey] = useState('');
  const [enterSearchIcon, setEnterSearchIcon] = useState(false);
  const [checkoutShippingDetails, setCheckoutShippingDetails] = useState([]);
  const [viewAllProducts, setViewAllProducts] = useState(false);
  const [filters, setFilter] = useState(searchFilter);
  const [searchTag, setSearchTag] = useState(setSearchTagKeys);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedCatHeader, setSelectedCatHeader] = useState(true);


  const value = {
    searchKey,
    setSearchKey,
    enterSearchIcon, setEnterSearchIcon,
    checkoutShippingDetails, setCheckoutShippingDetails,
    viewAllProducts, setViewAllProducts,
    filters, setFilter,
    searchTag, setSearchTag,
    selectedBrands, setSelectedBrands,
    selectedCatHeader, setSelectedCatHeader
  };

  return <MainUIContext.Provider value={value}>{children}</MainUIContext.Provider>;
}
