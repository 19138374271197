// Packages Block
import axios from "axios";

// Config constant Block
import {
  LOGIN_URL,
  ME_URL,
  SALE_AGENT_LOGIN_URL,
  SALE_AGENT_ASSIGN_CUSTOMER_URL,
} from "../../constants/config";

// Config.local constant Block
import {
  SALE_AGENT_LOGIN_URL_VIA_CUSTOMER,
  RESET_PASSWORD_URL,
  CHANGE_PASSWORD,
  REGISTER_CUSTOMER_URL,
  UPDATE_CUSTOMER_URL,
  CHECK_EMAIL,
} from "../../constants/config/config.local";

// Constant Types Block
import {
  CUSTOMER_DATA,
  LOGIN_SUCCESS,
  LOGOUT_WITH_SUCCESS,
  ONBOARDED,
  SET_LOGIN_SUCCESS,
  SET_LOGOUT_SUCCESS,
  SET_TOKEN_VIA_IMPERSONATE,
  UPDATE_CUSTOMER_SUCCESS,
} from "../constant_types";
import {
  UPDATE_TOKEN_SUCCESS,
  LOGIN_SALE_AGENT_SUCCESS,
  FETCH_ASSIGN_CUSTOMERS_SUCCESS,
  SALE_AGENT_VIA_CUSTOMER_SUCCESS,
} from "../constant_types";
import { SET_SALE_AGENT_VIA_CUSTOMER_LOADER_SUCCESS } from "../constant_types";

// others
import { deleteAllFromCart, getCustomerCart } from "./cartActions";
import Axios from "axios";

const loginSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  payload: user,
});
export const onboardAch = () => ({
  type: ONBOARDED,
  payload: {},
});

const registerCustomerSuccess = (customer) => ({
  type: LOGIN_SUCCESS,
  payload: customer,
});

const loginSaleAgentSuccess = (user) => ({
  type: LOGIN_SALE_AGENT_SUCCESS,
  payload: user,
});

const setSaleAgentViaCustomerSuccess = (saleAgent) => ({
  type: SALE_AGENT_VIA_CUSTOMER_SUCCESS,
  payload: saleAgent,
});

const setTokenViaImpersonateAct = (token) => ({
  type: SET_TOKEN_VIA_IMPERSONATE,
  payload: token,
});

const setLoginDetailSuccess = (user) => ({
  type: SET_LOGIN_SUCCESS,
  payload: user,
});

const setCustomerData = (user) => ({
  type: CUSTOMER_DATA,
  payload: user,
});

const setUpdateCustomer = (user) => ({
  type: UPDATE_CUSTOMER_SUCCESS,
  payload: user,
});

const logoutSuccess = () => ({
  type: SET_LOGOUT_SUCCESS,
});

const setAuthSuccess = (token) => ({
  type: UPDATE_TOKEN_SUCCESS,
  payload: token,
});

const setLogoutWithSuccess = (logout) => ({
  type: LOGOUT_WITH_SUCCESS,
  payload: logout,
});

const setFetchAssignCustomersSuccess = (customers) => ({
  type: FETCH_ASSIGN_CUSTOMERS_SUCCESS,
  payload: customers,
});

const setSaleAgentViaCustomerLoaderSuccess = (bool) => ({
  type: SET_SALE_AGENT_VIA_CUSTOMER_LOADER_SUCCESS,
  payload: bool,
});

export const loginUser = (payload, addToast, history) => {
  return (dispatch) => {
    axios
      .post(`${LOGIN_URL}`, { ...payload })
      .then((res) => {
        dispatch(getCustomerCart());
        dispatch(loginSuccess(res.data.entity));
        localStorage.setItem("user", JSON.stringify(res.data.entity));
        if (res.status === 200) {
          localStorage.setItem("passwordVerificationModal", 1);
        }
        if (addToast) {
          addToast(res.data.message, {
            appearance: "success",
            autoDismiss: true,
            PlacementType: "top-right",
            placement: "top-right",
          });
          // This message is added to remind customer's for change their password due to security reasons.
          addToast(
            "Recommend changing your password from settings for security purposes.",
            {
              appearance: "success",
              autoDismiss: true,
            }
          );
        }

        history.push(`/`);
      })
      .catch((err) => {
        if (addToast && !!err?.response) {
          err.response.data.errors.map((error) =>
            addToast(error, {
              appearance: "error",
              autoDismiss: true,
              PlacementType: "top-right",
              placement: "top-right",
            })
          );
        }
      });
  };
};

export const setTokenViaImpersonate = (payload) => {
  return (dispatch) => {
    dispatch(setTokenViaImpersonateAct(payload));
    const user = JSON.parse(localStorage.getItem("user"))||{}
    user["access_token"]=payload
    localStorage.setItem("user",JSON.stringify(user))
    dispatch(getCustomerData(payload))
  }
}
// Register Customer
export const registerCustomer = (payload, addToast, setCurrentStep, currentStep) => {
  const readytoSubmitValues = {...payload,
    business_certificate_expiry: payload.business_certificate_expiry==='' ? null : payload.business_certificate_expiry,
    tobacco_license_expiry: payload.tobacco_license_expiry==='' ? null : payload.tobacco_license_expiry,
    state_id_license_expiry: payload.state_id_license_expiry==='' ? null : payload.state_id_license_expiry,
    resale_certificate_expiry: payload.resale_certificate_expiry==='' ? null : payload.resale_certificate_expiry}
  return (dispatch) => {
    axios
      .post(`${REGISTER_CUSTOMER_URL}`, { ...readytoSubmitValues })
      .then((res) => {
        dispatch(registerCustomerSuccess(res.data));
        if (addToast) {
          addToast(res.data.message, {
            appearance: "success",
            autoDismiss: true,
            PlacementType: "top-right",
            placement: "top-right",
          });
        }
        setCurrentStep(currentStep + 1);
      })
      .catch((err) => {
        if (addToast && !!err?.response) {
          err.response.data.errors.map((error) =>
            addToast(error, {
              appearance: "error",
              autoDismiss: true,
              PlacementType: "top-right",
              placement: "top-right",
            })
          );
        }
      });
  };
};
export const checkEmail = async (payload, addToast) => {
  try {
    const response = await axios.get(`${CHECK_EMAIL}?email=${(payload)}`);
    return response.data;
  } catch (error) {
    console.error('Error checking email:', error);
    if (addToast && error?.response) {
      const errors = error.response.data.errors || [];
      errors.forEach(errorMsg => {
        addToast(errorMsg, {
          appearance: "error",
          autoDismiss: true,
          PlacementType: "top-right",
          placement: "top-right",
        });
      });
    }

    throw error;
  }

}

export const fetchCustomerAgainstSaleAgent = ({ token, customer_id }) => {
  let requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token?.replaceAll('"', "")}`,
    },
    body: JSON.stringify({
      customer_id,
    }),
  };
  return (dispatch) => {
    fetch(`${SALE_AGENT_LOGIN_URL_VIA_CUSTOMER}`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        const { agent, customer, access_token, refresh_token } = res.entity;
        localStorage.setItem(
          "user",
          JSON.stringify({
            customer: customer,
            access_token: access_token,
            refresh_token: refresh_token,
          })
        );
        dispatch(
          setSaleAgentViaCustomerSuccess({
            agent,
            customer,
            access_token,
            refresh_token,
          })
        );

        dispatch(getCustomerCart());
      })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const setSaleAgentViaCustomerLoader = (bool) => {
  return (dispatch) => {
    dispatch(setSaleAgentViaCustomerLoaderSuccess(bool));
  };
};

export const loginSaleAgent = (payload, addToast, history) => {
  return (dispatch) => {
    axios
      .post(`${SALE_AGENT_LOGIN_URL}`, { ...payload })
      .then((res) => {
        dispatch(loginSaleAgentSuccess(res.data.entity));
        localStorage.setItem("sale_agent", JSON.stringify(res.data.entity));
        if (addToast) {
          addToast(res.data.message, {
            appearance: "success",
            autoDismiss: true,
            PlacementType: "top-right",
            placement: "top-right",
          });
        }
        history.push(`/sale-agent/assign-customers`);
      })
      .catch((err) => {
        if (addToast) {
          err.response.data.errors.map((error) =>
            addToast(error, {
              appearance: "error",
              autoDismiss: true,
              PlacementType: "top-right",
              placement: "top-right",
            })
          );
        }
      });
  };
};

// update user
export const updateCustomerAction = (payload, userDetail, addToast) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userDetail.authToken}`,
  };
  return (dispatch) => {
    axios
      .put(`${ME_URL}`, { ...payload }, { headers })
      .then((res) => {
        let customer = {
          customer: res.data.entity,
          access_token: userDetail.authToken,
          refresh_token: userDetail.refreshToken,
        };

        localStorage.setItem("user", JSON.stringify(customer));
        dispatch(setUpdateCustomer(res.data.entity));

        if (addToast) {
          addToast(`${res.data.message}`, {
            appearance: "success",
            autoDismiss: true,
          });
        }
      })
      .catch((err) => {
        if (addToast) {
          err.response.data.errors.map((error) => {
            addToast(error, {
              appearance: "error",
              autoDismiss: true,
            });
          });
        }
      });
  };
};
export const updateCustomerAccount = (payload, userDetail, addToast) => {
  delete payload?.customer_shipping_details?.address_two;
  if(payload?.customer_billing_details){
  delete payload?.customer_billing_details?.address_two;
  }
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userDetail.authToken}`,
  };
  return (dispatch) => {
    axios
      .patch(`${UPDATE_CUSTOMER_URL}`, { ...payload }, { headers })
      .then((res) => {
        let customer = {
          customer: res.data.entity,
          access_token: userDetail.authToken,
          refresh_token: userDetail.refreshToken,
        };
        dispatch(getMe())
        localStorage.setItem("user", JSON.stringify(customer));
        dispatch(setUpdateCustomer(res.data.entity));

        if (addToast) {
          addToast(`${res.data.message}`, {
            appearance: "success",
            autoDismiss: true,
          });
        }
      })
      .catch((err) => {
        if (addToast) {
          err.response.data.errors.map((error) => {
            addToast(error, {
              appearance: "error",
              autoDismiss: true,
            });
          });
        }
      });
  };
};
export const setUser = () => {
  return (dispatch) => {
    if (localStorage.getItem("user") != null) {
      dispatch(setLoginDetailSuccess(JSON.parse(localStorage.getItem("user"))));
    }
  };
};
export const getMe = (token) => {
  return (dispatch) => {
    axios
      .get(`${ME_URL}`)
      .then((res) => {
        dispatch(
          setCustomerData({
            customer: res?.data?.entity,
          })
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };
};
export const getCustomerData = (token) => {
  return (dispatch) => {
    axios.get(`${ME_URL}`, {
      headers: { Authorization: `Bearer ${token}` }
    }).then((res) => {
      dispatch(
        setCustomerData({
          customer: res?.data?.entity,
        })
      );
    })
      .catch((err) => {
        console.error(err);
      });
  };
};

export const logoutUser = (history) => {
  return (dispatch) => {
    localStorage.removeItem("user");
    localStorage.removeItem("passwordVerificationModal");
    dispatch(deleteAllFromCart());
    dispatch(logoutSuccess());
    // window.location.reload();
    history.push(`/login`);
  };
};

export const setAuthToken = ({ accessToken, refreshToken }) => {
  return (dispatch) => {
    dispatch(setAuthSuccess({ accessToken, refreshToken }));
  };
};

export const logoutWithWithNormal = (logout) => {
  return (dispatch) => {
    dispatch(setLogoutWithSuccess(logout));
  };
};

export const fetchAssignCustomers = (params, addToast) => {
  return (dispatch) => {
    axios
      .get(`${SALE_AGENT_ASSIGN_CUSTOMER_URL}`, { ...params })
      .then((res) => {
        dispatch(setFetchAssignCustomersSuccess(res.data.entity));
      })
      .catch((err) => {
        if (addToast) {
          try {
            err.response.data.errors.map((error) =>
              addToast(error, {
                appearance: "error",
                autoDismiss: true,
                PlacementType: "top-right",
                placement: "top-right",
              })
            );
          } catch (error) {
            console.error(
              error
            );
          }
        }
      });
  };
};
export const customerResetPassword = (payload) => {
  return axios.post(`${RESET_PASSWORD_URL}/otp`, { ...payload });
};

export const customerRenewPassword = (payload) => {
  return axios.post(`${RESET_PASSWORD_URL}`, { ...payload });
};
export const changeCustomerPassword = (payload) => {
  return axios.patch(`${CHANGE_PASSWORD}`, { ...payload });
};
