import moment from 'moment-timezone';
import numeral from "numeral";
import toPath from "lodash-es/toPath";
import clone from "lodash-es/clone";
import { isObject } from "formik";
import React from "react";
import * as Yup from "yup";
import axios from "axios";
import {
  BACKGROUND_TASK_STATUS,
  SALE_AGENT_LOGIN_URL_VIA_CUSTOMER,
} from "../../constants/config/config.local";
import {
  CELERY_PROGRESS_STATUS,
  CELERY_PROGRESS_STATUS_OBJECT,
  EMAIL_REG_EXP,
  PASSWORD_REG_EXP,
  PRIVACY_POLICY,
  ProductVariantDisplayType,
  TERMS_AND_CONDITION,
  TERMS_AND_CONDITIONS_AND_PRIVACY_POLICY,
  ZIP_CODE_LENGTH,
  defaultImageUrl,
  NUMBER_OF_CHARACTER_FOR_LICENSE_FILE_NAME
} from "./Constants";
import { isEmpty } from "lodash";
import { getDiscountPrice } from "../../helpers/product";
import confirm from "antd/lib/modal/confirm";
import { ExclamationCircleOutlined } from "@ant-design/icons";

// Mask Number
export const maskAccountNumber = (number) => {
  return number ? `**** **** **** ${number.slice(-4)}` : "";
};

// Loader Script
export const loadScript = (src, setDataTokenizationKey) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;
    script.setAttribute("data-tokenization-key", setDataTokenizationKey);
    script.async = true;
    script.onload = () => resolve(script);
    script.onerror = () => reject(new Error(`Script load error for ${src}`));
    document.body.appendChild(script);
  });
};

let yourDate = new Date();
let minDate = yourDate.toISOString().split("T")[0];

export function formatDateTime(dateTime) {

  return moment(dateTime).format("MMM D, YYYY");
}

export const fileNameDetector = (url) => {
  const pathname = new URL(url)?.pathname;
  const filename = pathname.split('/').pop();
  return filename.slice(0, NUMBER_OF_CHARACTER_FOR_LICENSE_FILE_NAME);
}

export const handleDownload = (url, filename = "liscence-certificate") => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const blobURL = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobURL;
      link.download = filename;
      link.click();
    });
};
export const parseSpecialChr = (dist_name) => {
  let specialCharsRegex = /[^a-z0-9-()]/gi;
  const name = dist_name?.toString() || "dist_name";
  return name.replace(specialCharsRegex, "-").replace(/\(/g, "-").replace(/\)/g, "-");
};

export const openInNewTab = (url) => {
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.target = '_blank';
  anchor.rel = 'noopener noreferrer';
  anchor.click();
};

export function backendFormatDateTime(dateTime) {
  return moment(dateTime).format("YYYY-MM-DD");
}

export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

export const youtubeLinkIdParser = (url) => {
  var r,
    rx =
      /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
  r = url.match(rx);
  return r != null ? r[1] : false;
};

export const formatCurrency = (amount) => {
  return Number(numeral(amount).format("0.00"));
};

export const formatTaxAmount = (amount) => {
  return Number(numeral(amount).format("0.000"));
}

export function getObjectValue(list, key) {
  let type = "";
  for (let i = 0; i < list.length; i++) {
    if (list[i].value == key) {
      type = list[i].type;
      break;
    }
  }
  return type;
}

export function getValue(obj, key) {
  return Object.keys(obj).map((item) => {
    return obj[item].value === key ? obj[item].type : "";
  });
}

export const createFormValidationSchema = (currentStep, requiredLengthShipping, requiredLength, requiredLengthBilling) => {
  return Yup.lazy((values) => {
    switch (currentStep) {
      case 0:
        return Yup.object().shape({
          business_name: Yup.string().required("Business name is required!"),
          class_of_trades: Yup.string().required(
            "Class of Trades is required!"
          ),
          business_phone_no: Yup.string()
            .required("Business phone number is required!")
            .min(requiredLength, "Business phone number is not valid")
            .nullable(),

          tax_expiry_date: Yup.date()
            .min(minDate, "expiry date cannot be before todays date")
            .when(["tax_exempt_code"], (tax_exempt_code, schema) => {
              if (values.tax_exempt_code) {
                return schema.required(
                  IS_REQUIRED_MESSAGE("Tax Exempt Expiry Date")
                );
              }
            })
            .nullable()
            .test({
              name: "Name",
              exclusive: false,
              params: {},
              message: "Name is required ",
              test: function (value) {
                return ifOneThenAll(value, this);
              },
            }),
          name: Yup.string()
            .max(100, MAX_MESSAGE("Name", 100))
            .required("Contact name is required"),
          email: Yup.string()
            .matches(EMAIL_REG_EXP, "Email format is incorrect!")
            .required("Email is required!"),

          password: Yup.string()
            .matches(
              PASSWORD_REG_EXP,
              "At least 8 char with 1 upper case, 1 lower, 1 special and 1 number required"
            )
            .required("Password is required!"),

          confirm_password: Yup.string()
            .required("Password confirmation is required")
            .oneOf([Yup.ref("password"), null], "Passwords must match"),
          // Validation for fields in Step 1
        });
      case 1:
        return Yup.object().shape({
          customer_shipping_details: Yup.object().shape({
            address: Yup.string()
              .min(5, MIN_MESSAGE("Address", 5))
              .max(90, MAX_MESSAGE("Address", 90))
              .required("Address is required"),
            telephone_num: Yup.string()
              .required("Business phone number is required!")
              .min(requiredLengthShipping, "Business phone number is not valid")
              .nullable(),
            country: Yup.string().required("Country is required"),
            city: Yup.string().required("City is required"),
            state: Yup.string().required("State is required"),
            zip_code: Yup.string()
              .max(
                ZIP_CODE_LENGTH,
                `Zip code must be at most ${ZIP_CODE_LENGTH} characters`
              )
              .required("Zip Code is required"),
            tax_jurisdiction: Yup.string().required(
              "Tax Jurisdiction is required"
            ),
            is_billing_same: Yup.string()
              .required("This field is required")
              .oneOf(["Y", "N"], "Invalid selection"),
          }),
          customer_billing_details: Yup.object().when(
            "customer_shipping_details.is_billing_same",
            {
              is: "N", // Only validate billing details if is_billing_same is 'N'
              then: Yup.object().shape({
                address: Yup.string()
                  .min(5, MIN_MESSAGE("Address", 5))
                  .max(90, MAX_MESSAGE("Address", 90))
                  .required("Address is required"),
                country: Yup.string().required("Country is required"),
                telephone_num: Yup.string()
                  .required("Business phone number is required!")
                  .min(requiredLengthBilling, "Business phone number is not valid")
                  .nullable(),
                city: Yup.string().required("City is required"),
                state: Yup.string().required("State is required"),
                zip_code: Yup.string()
                  .max(
                    ZIP_CODE_LENGTH,
                    `Zip code must be at most ${ZIP_CODE_LENGTH} characters`
                  )
                  .required("Zip Code is required"),
              }),
              otherwise: Yup.object(), // No validation if is_billing_same is 'Y'
            }
          ),
        });

      case 2:
        return Yup.object().shape({
          business_certificate_expiry: Yup.string()
            .when([], (v, { originalValue }) => {
              if (values.business_certificate || values.business_certificate_num) {
                return Yup.string().required().label("Business Certificate Expiry").nullable();
              } else return Yup.date().min(minDate, "expiry date cannot be before todays date").typeError("Select valid date.").nullable();
            }),
          business_certificate_num: Yup.string()
            .when([], (v, { originalValue }) => {
              if (values.business_certificate || values.business_certificate_expiry) {
                return Yup.string().trim().required().label("Business Certificate Number").nullable();
              } else return Yup.string().nullable();
            }),
          // Commenting this for now as per POs Suggestions
          // business_certificate: Yup.string()
          // .when([], (v,{originalValue}) => {
          //   if (values.business_certificate_num || values.business_certificate_expiry) {
          //     return Yup.string().required().label("Business Certificate").nullable();
          //   } else return Yup.mixed().nullable();
          // }),
          tobacco_license_expiry: Yup.string()
            .when([], (v, { originalValue }) => {
              if (values.tobacco_license || values.tobacco_license_num) {
                return Yup.string().required().label("Tobacco Certificate Expiry").nullable();
              } else return Yup.date().min(minDate, "expiry date cannot be before todays date").typeError("Select valid date.").nullable();
            }),
          tobacco_license_num: Yup.string()
            .when([], (v, { originalValue }) => {
              if (values.tobacco_license || values.tobacco_license_expiry) {
                return Yup.string().trim().required().label("Tobacco Certificate Number").nullable();
              } else return Yup.string().nullable();
            }),
          // tobacco_license: Yup.string()
          // .when([], (v,{originalValue}) => {
          //   if (values.tobacco_license_num || values.tobacco_license_expiry) {
          //     return Yup.string().required().label("Tobacco Certificate").nullable();
          //   } else return Yup.mixed().nullable();
          // }),

          resale_certificate_expiry: Yup.string()
            .when([], (v, { originalValue }) => {
              if (values.resale_certificate || values.resale_certificate_num) {
                return Yup.string().required().label("Resale Certificate Expiry").nullable();
              } else return Yup.date().min(minDate, "expiry date cannot be before todays date").typeError("Select valid date.").nullable();
            }),
          resale_certificate_num: Yup.string()
            .when([], (v, { originalValue }) => {
              if (values.resale_certificate || values.resale_certificate_expiry) {
                return Yup.string().trim().required().label("Resale Certificate Number").nullable();
              } else return Yup.string().nullable();
            }),
          // resale_certificate: Yup.string()
          // .when([], (v,{originalValue}) => {
          //   if (values.resale_certificate_num || values.resale_certificate_expiry) {
          //     return Yup.string().required().label("Resale Certificate").nullable();
          //   } else return Yup.mixed().nullable();
          // }),

          // state_id_license:Yup.string()
          // .when([], (v,{originalValue}) => {
          //   if (values.state_id_license_num || values.state_id_license_expiry) {
          //     return Yup.string().required().label("State ID Certificate").nullable();
          //   } else return Yup.mixed().nullable();
          // }),
          state_id_license_expiry: Yup.string()
            .when([], (v, { originalValue }) => {
              if (values.state_id_license || values.state_id_license_num) {
                return Yup.string().required().label("State ID Certificate Expiry").nullable();
              } else return Yup.date().min(minDate, "expiry date cannot be before todays date").typeError("Select valid date.").nullable();
            }),
          state_id_license_num: Yup.string()
            .when([], (v, { originalValue }) => {
              if (values.state_id_license || values.state_id_license_expiry) {
                return Yup.string().trim().required().label("State ID Certificate Number").nullable();
              } else return Yup.string().nullable();
            }),
          customer_other_licenses: values.customer_other_licenses?.length > 0
            ? Yup.array().of(
              Yup.object().shape({
                name: Yup.string().required(IS_REQUIRED_MESSAGE("Name")),
                number: Yup.string().trim().required(IS_REQUIRED_MESSAGE("Number")),
                expiry_date: Yup.date().required(IS_REQUIRED_MESSAGE("Expiry Date")),
                file: Yup.mixed().required(IS_REQUIRED_MESSAGE("File")),
              })
            )
            : Yup.array(),
        });
      default:
        return Yup.object().shape({
          // You can return an empty schema or basic validation rules that are common to all steps
        });
    }
  });
};

export function conditionallyRender(condition, alternateText) {
  return condition ? condition : alternateText;
}

export function TextError(props) {
  return <label className="text-danger">{props.children}</label>;
}
export function moveIntoObject(obj, key) {
  let p = 0;
  var path = toPath(key);

  while (obj && p < path.length) {
    obj = obj[path[p++]];
  }
  return obj;
}
export function getPath(key, obj) {
  var resVal = clone(obj);
  let pathArray = toPath(key);
  let isValid;
  if (Object.keys(resVal).length) {
    if (pathArray.length === 1) {
      isValid = obj[pathArray[0]];
    } else {
      for (var i = 0; i < pathArray.length; i++) {
        var currentPath = pathArray[i];
        var currentObj = moveIntoObject(obj, pathArray.slice(0, i + 1));

        if (currentObj && (isObject(currentObj) || Array.isArray(currentObj))) {
          resVal = resVal[currentPath] = clone(currentObj);
        } else {
          isValid = currentObj;
        }
      }
    }
  }
  return isValid;
}

// Formik Validations
export const VALIDATION_MESSAGE = (name) => {
  return `${name} is not valid`;
};
export const IS_REQUIRED_MESSAGE = (str) => {
  return `${str} is required`;
};
export const LEN_MESSAGE = (len, maxmin) => {
  if (maxmin === "max") {
    return `Max ${len} characters`;
  } else if (maxmin === "min") {
    return `Min ${len} characters`;
  }
};
export const MAX_MESSAGE = (name, size) => {
  return `${name} cannot be greater than ${size} character`;
};
export const MIN_MESSAGE = (name, size) => {
  return `${name} cannot be less than ${size} character`;
};
export function ifOneThenAll(value, _this) {
  return true;
  // for (let key in _this.parent) {
  //   if (
  //     _this.parent[key] !== undefined &&
  //     _this.parent[key] !== null &&
  //     !value
  //   ) {
  //     return true;
  //   }
  // }
}
export const tooltipData = {
  icon: "../../assets/images/information2.svg",
};
export const TOOLTIP_BG = {
  color: "#F0F0F0",
  textColor: "black",
};

export const getPriceRelatedToCustomer = (userData, product, uniqueTaxValues) => {
  if ([null, "", "null", undefined].includes(userData?.tier_id)) {
    return formatCurrency(product?.unit_price) ||
      product?.selected_unit_price?.promotion?.min_order_qty <= product.quantity ? formatCurrency(product?.selected_unit_price?.promotion?.price) : formatCurrency(product?.selected_unit_price?.price)
    || formatCurrency(product?.selected_unit_price?.price);
  }
};
export const getPriceCheckout = (userData, product, uniqueTaxValues) => {
  if ([null, "", "null", undefined].includes(userData?.tier_id)) {
    return formatCurrency(product?.unit_price) || formatCurrency(product?.selected_unit_price?.promotion?.price) || formatCurrency(product?.selected_unit_price?.price);
  }
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

// Function to set a cookie
export function setCookie(name, value, days) {
  const expires = new Date(
    Date.now() + days * 24 * 60 * 60 * 1000
  ).toUTCString();
  document.cookie = `${name}=${value}; expires=${expires}; path=/`;
}

// Function to get a cookie by name
export function getCookie(name) {
  const cookieValue = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
  return cookieValue ? cookieValue[2] : null;
}

// navigate to google.com function
export const navigateToGoogle = () => {
  window.location.href = "https://www.google.com";
};

export function getParameterValue(paramName) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(paramName);
}

// Function to check if token and customer_id is present in url then call the api to get the customer data and set the user in local storage
export function sendPostRequest(customerId, token) {
  // URL of  API endpoint
  const apiUrl = `${SALE_AGENT_LOGIN_URL_VIA_CUSTOMER}`;
  // request body
  const requestBody = JSON.stringify({ customer_id: Number(customerId) });
  // Set up the request headers
  const headers = new Headers();
  headers.append("Authorization", `Bearer ${token}`);
  headers.append("Content-Type", "application/json");

  // POST request options
  const requestOptions = {
    method: "POST",
    headers: headers,
    body: requestBody,
  };

  // Send the POST request
  fetch(apiUrl, requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => {
      const { customer, access_token, refresh_token } = data.entity;
      localStorage.setItem(
        "user",
        JSON.stringify({
          customer: customer,
          access_token: access_token,
          refresh_token: refresh_token,
        })
      );
      localStorage.setItem("passwordVerificationModal", 1);
      window.location.replace("/");
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

export const dateStringApplyOffSet = (value, timeZone) => {
  if (!value || value === "" || value === null) {
    value = new Date().toISOString().split("T")[0];
  }
};


export const dateStringApplyOffSetNew = (value, timeZone) => {

  try {
    if (!value || value === "" || value === null) {
      value = new Date().toISOString().split("T")[0];
    }
    var dateInMilliSeconds = new Date(value).getTime();
    var timezoneOffset;
    if (timeZone && timeZone.value) {
      timezoneOffset = moment.tz.zone(timeZone.value).utcOffset(dateInMilliSeconds);
    } else {
      throw new Error("Invalid timezone value");
    }
    const date = new Date(dateInMilliSeconds + timezoneOffset * 60 * 1000).toISOString().split("Z")[0];
    return date;
  } catch (error) {
    console.error("Error in dateStringApplyOffSetNew:", error);
    return null;
  }
};
export const addTime = (value, timeZone) => {
  try {
    if (!value.includes("T")) {
      return value + "T00:00:00.000";
    }

    // Check if the date string matches the full expected format
    const fullFormatRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}$/;
    if (fullFormatRegex.test(value)) {
      return value;
    }

    // Check if the date string matches the format without milliseconds
    const noMillisecondsRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
    if (noMillisecondsRegex.test(value)) {
      return value + ".000";
    }

    // Check if the date string matches the date-only format
    const dateOnlyRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (dateOnlyRegex.test(value)) {
      return value + "T00:00:00.000";
    }

    // If none of the formats match, return an error message
    return "Invalid date format";

  } catch (error) {
    console.error("Error in dateStringApplyOffSetNew:", error);
    return null;
  }
}

// export function getTimeByTimeZone(timezone, dateTime) {
//   if (!timezone || !dateTime) {
//     return;
//   }
//   const utcDate = new Date(dateTime);
//   const offsetParts = timezone.offset;
//   // const hoursOffset = parseInt(offsetParts);
//   const convertedTime = new Date(utcDate.getTime() + offsetParts * 60 * 60000);
//   const convertedTimezone = formatDateTime(convertedTime);

//   return convertedTimezone;
// }

export function getTimeByTimeZone(timezone, dateTime) {
  if (!timezone || !dateTime) {
    return
  }
  const localTime = moment.utc(dateTime).tz(timezone.value)?.format('YYYY-MM-DDTHH:mm:ss');
  const convertedTimezone = formatDateTime(localTime);
  return convertedTimezone;
}

export function getTimeByTimeZonePromotion(timezone, dateTime, format = 'YYYY-MM-DDTHH:mm:ss') {
  if (!timezone || !dateTime) {
    return;
  }

  return moment.utc(dateTime).tz(timezone.value).format(format);
};
// CHECK IMPORT STATUS
export function checkImportApiStatus(id) {
  return axios.get(`${BACKGROUND_TASK_STATUS}/${id}`);
}

export let backgroundTaskHandler = (exportKey, action) => {
  if (localStorage.getItem(exportKey) != "null") {
    checkImportApiStatus(localStorage.getItem(exportKey))
      .then((res) => {
        const { id, result, status: statusKey } = res.data.entity;
        action({
          status: CELERY_PROGRESS_STATUS[statusKey]?.slug,
          url: result?.download_url,
        });
        if (
          [
            CELERY_PROGRESS_STATUS_OBJECT.COMPLETED.id,
            CELERY_PROGRESS_STATUS_OBJECT.FAILED.id,
          ].includes(statusKey)
        ) {
          if (CELERY_PROGRESS_STATUS_OBJECT.FAILED.id == statusKey) {
            setTimeout(() => {
              action({
                status: null,
                url: null,
              });
            }, 0);
          }
          localStorage.setItem(exportKey, "null");
        } else if (
          ![
            CELERY_PROGRESS_STATUS_OBJECT.COMPLETED.id,
            CELERY_PROGRESS_STATUS_OBJECT.FAILED.id,
          ].includes(statusKey) &&
          backgroundTaskHandler
        ) {
          try {
            setTimeout(() => {
              if (typeof backgroundTaskHandler == "function") {
                backgroundTaskHandler();
              }
            }, 1500);
          } catch (error) {
            action({ status: "FAILURE", url: null });
            localStorage.setItem(exportKey, "null");
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
};

export const keysAndValues = (obj) => ({
  keys: Object.keys(obj),
  headers: Object.values(obj),
});

export const getImagesForImageDisplay = (product) => {
  try {
    let images = {};

    [
      {
        product_variant_attribute: product.product_variant_attribute,
        images: product.images,
      },
      ...product.variant_product,
    ].map((proEle) => {
      const containImage = proEle.product_variant_attribute.filter(
        ({ display_type }) =>
          display_type == ProductVariantDisplayType.IMAGE.type
      );
      containImage.map((containImageEle) => {
        if (!images[containImageEle.ecom_name]) {
          // temp.push(proEle.images[0]);

          images[containImageEle.ecom_name] = {
            [containImageEle.value]: proEle.images[0],
          };
        } else {
          if (
            !images[containImageEle.ecom_name]?.[containImageEle.value] ||
            images[containImageEle.ecom_name]?.[containImageEle.value] ==
            defaultImageUrl
          ) {
            images[containImageEle.ecom_name] = {
              ...images[containImageEle.ecom_name],
              [containImageEle.value]: proEle.images[0],
            };
          }
        }
      });
    });

    return images;
  } catch (err) {
    return {};
  }
};

export const madeCretria = ({ index, variantAttributes, values }) => {
  try {
    if (!index) {
      return false;
    }
    const result = {};
    variantAttributes.slice(0, index).map((element) => {
      result[element.ecom_name] =
        values.product_variant_attribute.find(
          (ell) => ell.ecom_name == element.ecom_name
        ).value;
    });
    return result;
  } catch (err) {
    return {};
  }
};

export const findCombinations = ({
  data,
  index,
  variantAttributes,
  values,
  availability,
}) => {
  // let criteria = madeCretria({ index, variantAttributes, values });
  // Preprocess criteria to lower case
  if (index === 0) {
    return [...data]
  }

  const lowerCaseCriteria = Object.entries({
    ...values,
    ...availability,
  }).reduce((acc, [key, value]) => {
    acc[key.toLowerCase()] = value.toString().toLowerCase();
    return acc;
  }, {});

  if (index && isEmpty(values)) {
    return [];
  }
  return data.filter((item) => {
    return Object.entries(lowerCaseCriteria).every(([key, value]) => {
      return item.product_variant_attribute.some((attribute) => {
        return (
          attribute.ecom_name.toLowerCase() === key &&
          attribute.value.toString().toLowerCase() === value
        );
      });
    });
  });
};

export const cartGrandTotal = (cartItems, userDetail, settings) => {
  let cartTotalPrice = 0;
  cartItems.map((product, key) => {
    const discountedPrice = getDiscountPrice(product.price, product.discount);
    const finalProductPrice = +(
      product?.unit_price ||
      getPriceRelatedToCustomer(userDetail, product) * settings.currencyRate
    ).toFixed(2);

    const finalDiscountedPrice = (discountedPrice * settings.currencyRate).toFixed(2);

    discountedPrice != null
      ? (cartTotalPrice += finalDiscountedPrice * product.quantity)
      : (cartTotalPrice += finalProductPrice * product.quantity);
  });
  let total = 0;
  total =
    cartTotalPrice +
    (settings.tax_type === 2
      ? settings?.tax || 0
      : (settings?.tax * cartTotalPrice) / 100);
  return total;
};

export function extractYouTubeVideoId(url) {
  let videoId = null;

  // Check if the URL is a standard YouTube URL
  const standardUrlPattern = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/.*[?&]v=([^&]+)/;
  const shortUrlPattern = /(?:https?:\/\/)?youtu\.be\/([^?&]+)/;

  // Try to match against the standard URL pattern
  const matchStandard = url.match(standardUrlPattern);
  if (matchStandard && matchStandard[1]) {
    videoId = matchStandard[1];
  } else {
    // Try to match against the short URL pattern
    const matchShort = url.match(shortUrlPattern);
    if (matchShort && matchShort[1]) {
      videoId = matchShort[1];
    }
  }

  return videoId;
}

export const findCategoryBySlug = (categories, slug) => {
  for (let category of categories) {
    if (category.slug === slug) {
      return category;
    }

    if (category.children && category.children.length > 0) {
      const childCategory = findCategoryBySlug(category.children, slug);
      if (childCategory) {
        return childCategory;
      }

      for (let child of category.children) {
        if (child.children && child.children.length > 0) {
          const secondLevelChildCategory = findCategoryBySlug(child.children, slug);
          if (secondLevelChildCategory) {
            return secondLevelChildCategory;
          }
        }
      }
    }
  }
  return null;
}

export const getImagesForAllVariants = (product) => {
  try {
    let images = {};

    [
      {
        product_variant_attribute: product.product_variant_attribute,
        images: product.images,
      },
      ...product.variant_product,
    ].map((proEle) => {
      const containImage = proEle.product_variant_attribute;
      containImage.map((containImageEle) => {
        if (!images[containImageEle.ecom_name]) {
          // temp.push(proEle.images[0]);

          images[containImageEle.ecom_name] = {
            [containImageEle.value]: proEle.images[0],
          };
        } else {
          if (
            !images[containImageEle.ecom_name]?.[containImageEle.value] ||
            images[containImageEle.ecom_name]?.[containImageEle.value] ==
            defaultImageUrl
          ) {
            images[containImageEle.ecom_name] = {
              ...images[containImageEle.ecom_name],
              [containImageEle.value]: proEle.images[0],
            };
          }
        }
      });
    });

    return images;
  } catch (err) {
    return {};
  }
};


export const customPagesTermsValidation = (customPages) => {
  return customPages?.entities?.find(entity => entity.name === TERMS_AND_CONDITION && entity.content.published === true);
}

export const customPagesTermsValidationCheck = (customPages) => {
  return customPages?.entities?.some(entity => entity?.content?.published === true);
}

export const customPagesPrivacyPolicyValidation = (customPages) => {
  return customPages?.entities?.find(entity => entity.name === PRIVACY_POLICY && entity.content.published === true);
}

export const validationMessageForTermsAndConditionAndPrivacyPolicy = (termsAndConditions, privacyPolicy) => {
  return `Please accept the ${termsAndConditions && privacyPolicy ? TERMS_AND_CONDITIONS_AND_PRIVACY_POLICY : termsAndConditions ? TERMS_AND_CONDITION : privacyPolicy ? PRIVACY_POLICY : TERMS_AND_CONDITION}`;
}

export const tobaccoEligibilityMessage = (licensePath, modalRef) => {
  const handleLinkClick = (e) => {
    e.preventDefault();
    modalRef.current.destroy();
    if (licensePath) {
      licensePath();
    }
  };
  return (
    <>
      You are currently not eligible to purchase tobacco products because your Tobacco Certificate is either not uploaded or has expired. Please <a style={{ color: "red" }} href="#" onClick={handleLinkClick}>visit your profile</a> to upload a valid license.
    </>
  );
};

export const isProductTobaccoEligible = (product, userData, settingData) => {
  return !product.is_tobacco || settingData?.skip_eligibility_check || userData?.tobacco_eligibility;
}

export const refModal = (modalRef, licensePath) => {
  modalRef.current = confirm({
    icon: <ExclamationCircleOutlined />,
    content: tobaccoEligibilityMessage(licensePath, modalRef),
    onOk() { },
    onCancel() { },
    cancelText: "Close",
  });
}

export const getSearchKey = () => {
  const searchURL = new URL(window.location.href);
  return decodeURIComponent(searchURL.searchParams.get("search") || '');
};