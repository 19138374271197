import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react';
import CategoryItem from './categoryItem'
import NavCategories from '../../navCategories/navCategories'


const Categories = ({ categoryTreeData = [], className = [], overflow = false, brandsData = {}, rowsCount = 1  }) => {
  const categoriesContainerRef = useRef(null);
  const categoryRefs = useRef([]);

  const [visibleCategories, setVisibleCategories] = useState([]);
  const [overflowedCategories, setOverflowedCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState({});

  categoryRefs.current = categoryTreeData?.map(
    (_, i) => categoryRefs.current?.[i] || React.createRef()
  );

  const calculateVisibleCategories = useCallback(() => {
    if (overflow) {
      setVisibleCategories((prev) =>
        prev !== categoryTreeData ? categoryTreeData : prev
      );
      setOverflowedCategories((prev) =>
        prev.length > 0 ? [] : prev
      );
      return;
    }

    if (!categoriesContainerRef.current || categoryRefs.current?.length === 0) {
      return;
    }

    const containerWidth =
      categoriesContainerRef.current.getBoundingClientRect().width;

    let treshHold = 100
    let totalWidth = treshHold;
    let lastIndex = -1;

    for (let i = 0; i < categoryRefs.current?.length; i++) {
      const categoryWidth = categoryRefs.current[i].current?.getBoundingClientRect().width;

      totalWidth += categoryWidth;

      const othersWidth = 120; // Static value
      if (totalWidth + othersWidth * rowsCount <= (containerWidth - treshHold) * rowsCount) {
        lastIndex = i;
      } else {
        break;
      }
    }

    const newVisibleCategories = categoryTreeData.slice(0, lastIndex + 1);
    const newOverflowedCategories = categoryTreeData.slice(lastIndex + 1);
  
    setVisibleCategories((prev) =>
      prev.length !== newVisibleCategories.length || !prev.every((item, index) => item === newVisibleCategories[index])
        ? newVisibleCategories
        : prev
    );
  
    setOverflowedCategories((prev) =>
      prev.length !== newOverflowedCategories.length || !prev.every((item, index) => item === newOverflowedCategories[index])
        ? newOverflowedCategories
        : prev
    );
  }, [categoryTreeData, overflow]);


  useEffect(() => {
    const recalculate = () => {
      calculateVisibleCategories();
    };

    document.fonts.ready.then(recalculate);

    const onFontsLoaded = () => {
      recalculate();
    };
  
    document.fonts.addEventListener('loadingdone', onFontsLoaded);

    const handleResize = () => recalculate();
    window.addEventListener('resize', handleResize);

    return () => {
      document.fonts.removeEventListener('loadingdone', onFontsLoaded);
      window.removeEventListener('resize', handleResize);
    };
  }, [calculateVisibleCategories]);

  return (
    <>
      {!overflow && (
        <div style={{ visibility: 'hidden', position: 'absolute', top: 0, left: 0 }}>
          {categoryTreeData?.map((category, index) => (
            <CategoryItem
              skipID={true}
              key={`cat-${index}`}
              categoryItem={category}
              categoryRef={categoryRefs.current[index]}
              className="!tw-inline-block"
            />
          ))}
        </div>
      )}
      <div
        className={`header-categories
          ${Array.isArray(className) ? className.join(' ') : className}
          ${overflow ? '!tw-flex-wrap !tw-justify-center' : ''}
          ${rowsCount > 1 ? '!tw-flex-wrap' : ''}
        `}
        ref={categoriesContainerRef}
        onMouseLeave={() => setActiveCategory({})}
      >
        {visibleCategories.map((category, index) => (
          <CategoryItem
            key={`visible-${index}`}
            categoryItem={category}
            handleActiveCategory={setActiveCategory}
          />
        ))}

        {!overflow && overflowedCategories.length > 0 && (
          <div
            className="category-item header-dropdown"
            onMouseEnter={() => setActiveCategory({})}
          >
            <button type="button" className="header-dropdown-button">
              Others
              <svg
                className="tw-h-5 tw-w-5 tw-flex-none tw-text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <ul className="header-list">
              {overflowedCategories.map((category, index) => (
                <li key={`overflow-${index}`}>
                  <CategoryItem categoryItem={category} />
                </li>
              ))}
            </ul>
          </div>
        )}
        <NavCategories activeCategory={activeCategory}/>
      </div>
    </>
  );
};

export default Categories;
