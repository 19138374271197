import React from "react"

import { profileNavigation } from "../../pages/constant/Constants";
import { logoutUser } from "../../redux/actions/loginActions";
import { store } from "../../index";

export const getMenuItems = (history, location, handleLogout) => [
  {
    label: (
      <div
      className={`header-icon-group-menu ${
        location.pathname === profileNavigation.MY_ACCOUNT ? "active" : ""
      }`}
        onClick={() => {
          history.push(profileNavigation.MY_ACCOUNT);
        }}
      >
        My Account
      </div>
    ),
    key: "0",
  },
  {
    label: (
      <div
        className={`header-icon-group-menu ${
          location.pathname === profileNavigation.ORDER_HISTORY ? "active" : ""
        }`}
        onClick={() => {
          history.push(profileNavigation.ORDER_HISTORY);
        }}
      >
        Order History
      </div>
    ),
    key: "1",
  },
  {
    label: (
      <div
        className={`header-icon-group-menu ${
          location.pathname === profileNavigation.STATEMENTS ? "active" : ""
        }`}
        onClick={() => {
          history.push(profileNavigation.STATEMENTS);
        }}
      >
        Statements
      </div>
    ),
    key: "2",
  },
  {
    label: (
      <div
        className="header-icon-group-menu"
        onClick={(e) => {
          e.preventDefault();
          store.dispatch(logoutUser(history))
        }}
      >
        Logout
      </div>
    ),
    key: "4",
  },
];
