import axios from "axios";
import {
  GET_CITY_URL,
  ECOM_ACH_ONBOARD_URL,
  BANK_DETAIL_URL,
  ECOM_ACH_ACCOUNT_DETAIL,
  CONSENT_TENANT,
} from "../../constants/config/config.local";

export const onBoardAch = (params) =>
  axios.post(ECOM_ACH_ONBOARD_URL, { ...params });
export const getOnBoardAch = () => axios.get(ECOM_ACH_ONBOARD_URL);

export const getCities = (params) =>
  axios.get(`${GET_CITY_URL}`, { params: params });
export const bankDetail = () => axios.get(`${BANK_DETAIL_URL}`);
export const achBankList = () => axios.get(`${BANK_DETAIL_URL}/list`);
export const setAchBankDefault = (payload) => axios.patch(`${BANK_DETAIL_URL}`,payload);
export const bankDetailACH = () =>
  axios.get(`${ECOM_ACH_ACCOUNT_DETAIL}/account_details`, {
    params: { fetch_bank: true },
  });
export const postBankDetail = (params) =>
  axios.put(`${BANK_DETAIL_URL}`, { ...params });
export const postBankDetailACH = (params) =>
  axios.put(`${ECOM_ACH_ACCOUNT_DETAIL}/bank`, { ...params });
export function consentTenant(payload) {
  return axios.post(`${CONSENT_TENANT}`, { ...payload });
}
