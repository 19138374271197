import React from "react";
import { useHistory } from "react-router-dom";

const Brands = ({ brandsData, showImage = false }) => {
  const history = useHistory();

  const getBrandURL = (brandSlug) => {
    const shopUrl = new URL('/shop', window.location.origin);
    shopUrl.searchParams.set('filter_brand', brandSlug);
    return shopUrl;
  };

  const handleBrandClick = (brand) => {
    const url = getBrandURL(brand.slug);
    history.push('/shop/' + url.search); // Update the URL with the query parameter
    localStorage.setItem("brandsPreserve", brand.id)
  };

  return (
    <ul className={`mega-menu-brands-list ${!showImage ? 'brands-list-text' : ''}`}>
      {brandsData?.map((brand, index) => (
        <li key={brand.id} className={`${showImage ? 'mega-menu-brand-item' : ''}`}>
          <div
            className={`tw-cursor-pointer tw-whitespace-break-spaces tw-overflow-hidden tw-text-ellipsis ${showImage ? 'mega-menu-brand-link' : ''}`}
            onClick={() => handleBrandClick(brand)}
          >
            {brand.image && showImage ? (
              <img src={brand.image} alt={brand} className="mega-menu-brand-image" title={brand.name.capitalize()}/>
            ) : (
              <span className="brand-alt">{brand.name.capitalize()}</span>
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default Brands;
