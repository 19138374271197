import React, { useMemo, useState } from "react";
import { useMainUIContext } from '../../../../UIContext';


const CategoryList = ({ categories, activeCategory }) => {
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const mainUIContext = useMainUIContext();
  const mainUIProps = useMemo(() => {
    return {
      setFilter:mainUIContext.setFilter,
    };
  }, [mainUIContext]);
  const { setFilter } = mainUIProps;
  const productSearchLocal = JSON.parse(localStorage.getItem("shop_search_filters") || '{}');

  const isCategoryActive = (category) => {
    return category.id === activeCategory?.id;
  };

  return (
    <div
      className="category-list-container"
      onMouseLeave={() => setHoveredCategory(null)}
    >
      <ul className="category-list">
        {categories.map((category) => (
          <li
            key={category.id}
            onMouseEnter={() => setHoveredCategory(category)}
            className={isCategoryActive(category) ? 'active-category' : ''}
          >
            <a
              // href={`/shop/${category.slug}`}
              className='tw-relative tw-pr-5'
              onClick={(event) => {
                event.preventDefault();
                if (productSearchLocal.search_filter) {
                  productSearchLocal.search_filter.brand_ids = null;
                  productSearchLocal.search_filter.is_hot_seller = null;
                  productSearchLocal.search_filter.is_featured = null;
                  productSearchLocal.search_filter.is_new_arrival = null;
                  localStorage.setItem("shop_search_filters", JSON.stringify(productSearchLocal));
                }
                setFilter(prev => ({
                  ...prev,
                  brand_ids: [],
                }));
                localStorage.setItem("brandsPreserve", "")
                localStorage.setItem("parentCategory", activeCategory?.id || category.parent_id);
                setTimeout(() => {
                  localStorage.removeItem("brandsPreserve");
                  window.location.href = `/shop/${category.slug}`;
                }, 100);
                // window.location.href = `/shop/${category.slug}`;
              }}
            >
              {category.name.capitalize()}
              {category.children && category.children.length > 0 && (
                <span className="arrow-icon tw-ml-auto tw-absolute tw-right-0">
                  <svg
                    className="tw-w-4 tw-h-4 tw-inline-block tw-ml-2"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7"></path>
                  </svg>
                </span>
              )}
            </a>
          </li>
        ))}
      </ul>

      {hoveredCategory && hoveredCategory.children.length > 0 && (
        <CategoryList 
          categories={hoveredCategory.children} 
          activeCategory={activeCategory}
        />
      )}
    </div>
  );
};

export default CategoryList;
