import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { connect } from "react-redux";
import Button from "../../components/utils/Button";
import SuccessImage from "../../../src/assets/images/success.gif";


const ThankYou = ({ orderDetail, settings }) => {
  const history = useHistory();
  return (
    <Fragment>
      <MetaTags>
        <title>{settings?.ecom_title} | Thank you</title>
        <meta
          name="description"
          content={`THANK YOU page of ${settings?.ecom_title} eCommerce template.`}
        />
      </MetaTags>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + history.location.pathname}>
        Thank you
      </BreadcrumbsItem>
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        {/* <Breadcrumb /> */}
        <div className="ThankYou-Wrapper">
          <div className="error" style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
            <img
              className="default-img"
              style={{
                height: "300px",
                width: "400px",
              }}
              src={SuccessImage}
              alt=""
            />
            <h4 className="Headingtext">Order has been placed.</h4>
            <p className="paraText">
              Your transaction has been done successfully.
            </p>
            <a
              href={`${process.env.PUBLIC_URL}/profile/order-history`}
              style={{
                textDecoration: "none",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                label="Go to Order History"
                style={{
                  width: "261px",
                  height: "52px",
                  borderRadius: "32px",
                  marginTop: "16px",
                  background: "#EA1E51",
                  color: "#fff",
                }}
              />
            </a>
            {/* 
            <Link
              to={`${process.env.PUBLIC_URL}/profile/order-history`}
              className="error-btn mr-3"
            >
              Order History
            </Link> */}
            {/* <Link to={process.env.PUBLIC_URL + "/"} className="error-btn">
                    Back to home page
                  </Link> */}
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

ThankYou.propTypes = {
  location: PropTypes.object,
};
const mapStateToProps = (state) => {
  return {
    orderDetail: state.saleData.singleOrder,
    settings: state.settingData.ecom.basic,
  };
};

export default connect(mapStateToProps)(ThankYou);
