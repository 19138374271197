import React from 'react';
import CategoryList from '../header/components/categoryList';
import Brands from './components/brands';

const NavCategoriesTemplate2 = ({ position, activeCategory, brandsData, isLoading }) => {
  const subCategories = activeCategory?.children ?? [];
  if (!Object.keys(activeCategory).length || (!brandsData.length && !subCategories.length)) return null;

  return (
    <div
      className="mega-menu"
      style={position && {top: position.top}}
    >
      <div className="dropdown-content">
        <div className="brand-info">
          <h2 className='boarded'>
            Brands
          </h2>
          {isLoading ? <span className='brand-alt'>Loading Brands...</span> : (<Brands brandsData={brandsData} />)}
        </div>

        {subCategories && subCategories.length > 0 && (
          <div className="tw-flex tw-flex-col">
            <b className="tw-ml-6 tw-capitalize tw-mb-3">{activeCategory.name.capitalize()}</b>
            <CategoryList activeCategory={activeCategory} categories={subCategories} />
          </div>
        )}
      </div>
    </div>
  );
};

export default NavCategoriesTemplate2;
