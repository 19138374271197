import React, {useMemo} from "react";
import { useMainUIContext } from '../../../../UIContext';
import { useHistory } from "react-router-dom";


const CategoryItem = ({ skipID, categoryItem, categoryRef, handleActiveCategory, className }) => {
  const history = useHistory();
  const mainUIContext = useMainUIContext();
  const mainUIProps = useMemo(() => {
    return {
      setFilter:mainUIContext.setFilter,
      setSelectedCatHeader:mainUIContext.setSelectedCatHeader
      
    };
  }, [mainUIContext]);
  const { setFilter,setSelectedCatHeader } = mainUIProps;
  if (!categoryItem) return null;

  const subCategories = categoryItem.children ?? {};
  const hasSubCategory = subCategories.length > 0;
  const productSearchLocal = JSON.parse(localStorage.getItem("shop_search_filters") || '{}');

  const currentSearch = new URLSearchParams(history.location.search);
  
  return (
    <a
      key={categoryItem.id + Math.random()}
      id={!skipID && 'category_item_' + categoryItem.id}
      href={`/shop/${categoryItem.slug}`}
      className={`category-item tw-items-center ${className ? className : ''} ${hasSubCategory ? 'has-sub-category' : ''}`}
      ref={categoryRef}
      onMouseEnter={() => handleActiveCategory && handleActiveCategory(categoryItem)}
      onClick={(event) => {
        event.preventDefault();
        if (productSearchLocal.search_filter) {
          productSearchLocal.search_filter.brand_ids = null;
          productSearchLocal.search_filter.is_hot_seller = null;
          productSearchLocal.search_filter.is_featured = null;
          productSearchLocal.search_filter.is_new_arrival = null;
          localStorage.setItem("shop_search_filters", JSON.stringify(productSearchLocal));
        }
        setSelectedCatHeader(false)
        setFilter(prev => ({
          ...prev,
          brand_ids: [],
        }));
        currentSearch.delete("filter_brand");
        currentSearch.delete("search");
        localStorage.setItem("brandsPreserve", "");
        setTimeout(() => {
          localStorage.removeItem("brandsPreserve");
          window.location.href = `/shop/${categoryItem.slug}`;
        }, 100);
        if(!hasSubCategory) localStorage.setItem("parentCategory", undefined);
        window.location.href = `/shop/${categoryItem.slug}`;
      }}
      style={{fontFamily: 'var(--style-secondary-font-family, "Montserrat", sans-serif)'}}
    >
      {categoryItem.name.capitalize()}
      {handleActiveCategory && hasSubCategory && (
        <svg
          className="tw-h-5 tw-w-5 tw-flex-none tw-text-gray-400"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
            clipRule="evenodd"
          />
        </svg>
      )}
    </a>
  );
};

export default CategoryItem;
