import React, { useState } from 'react';

const MobileCategories = ({ categoryTreeData}) => {

  const [expandedKeys, setExpandedKeys] = useState([]);

  const toggleAccordion = (categoryId) => {
    setExpandedKeys((prevKeys) =>
      prevKeys.includes(categoryId)
        ? prevKeys.filter((key) => key !== categoryId)
        : [...prevKeys, categoryId]
    );
  };

  const renderCategory = (category, depth = 0) => {
    const isExpanded = expandedKeys.includes(category.id);

    return (
      <div key={category.id} className={`category-container ${depth > 0 ? 'nested-category' : ''}`}>
        {/* Parent Category */}
        <a
          href={`/shop/${category.slug}`}
          className={`category-item tw-items-center ${isExpanded ? 'expanded' : ''}`}

        >
          {category.name}
          {category.children && category.children.length > 0 && (
            <span
              className={`arrow-icon tw-ml-auto tw-absolute tw-right-0 tw-cursor-pointer ${
                isExpanded ? 'tw-rotate-180' : ''
              }`}
              onClick={(e) => {
                if (category.children && category.children.length > 0) {
                  e.preventDefault()
                  toggleAccordion(category.id);
                }
              }}
            >
              <svg
                className="tw-w-4 tw-h-4 tw-inline-block tw-rotate-90 tw-origin-center"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7"></path>
              </svg>
            </span>
          )}
        </a>

        {/* Nested Children */}
        {isExpanded && category.children && category.children.length > 0 && (
          <div className="nested-children">
            {category.children.map((childCategory) =>
              renderCategory(childCategory, depth + 1)
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="header-categories">
      {categoryTreeData.map((category) => renderCategory(category))}
    </div>
  );
};

export default MobileCategories;
