import React from "react";
import { Link } from "react-router-dom";


export const NUMBER_OF_CHARACTER_FOR_LICENSE_FILE_NAME = 27;
export const ALPHA_NUMERIC_REG_EXP = /[a-zA-Z0-9]+/;
export const NUMBER_REGEX = /^\d+$/;
export const EMAIL_REG_EXP =
  /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
export const PASSWORD_REG_EXP =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}$/;
export const ZIP_CODE_LENGTH = 10;

export const TOOLTIP_BG = {
  color: "#F0F0F0",
  textColor: "black",
};

export const UNIT_VALUE = { PIECE: 1, PACK: 2, CASE: 3, PALLET: 4 };

export const UNIT = {
  PIECE: { name: "Piece", value: UNIT_VALUE.PIECE, subId: null },
  PACK: { name: "Pack", value: UNIT_VALUE.PACK, subType: "piece", subId: 1 },
  CASE: { name: "Case", value: UNIT_VALUE.CASE, subType: "pack", subId: 2 },
  PALLET: {
    name: "Pallet",
    value: UNIT_VALUE.PALLET,
    subType: "case",
    subId: 3,
  },
};

export const Units = {
  1: UNIT.PIECE,
  2: UNIT.PACK,
  3: UNIT.CASE,
  4: UNIT.PALLET,
};

// commented for future use

export const ORDER_STATUS = {
  PENDING: {
    value: 10,
    type2: "Pending",
    type: "Pending",
    color: "#4d783f",
    slug: "pending",
  },
  PICKER_ASSIGNED: {
    value: 15,
    type2: "Picker Assigned",
    type: "Picker Assigned",
    color: "#4d783f",
    slug: "picker_assigned",
  },
  PARTIALLY_EXECUTED: {
    value: 35,
    type2: "Partially Executed",
    type: "Partially Executed",
    color: "#95a5a6",
    slug: "partially_executed",
  },
  PICKING_IN_PROGRESS: {
    value: 21,
    type2: "Picking in progress",
    type: "Picked in progress",
    color: "#117a8b",
    slug: "picking_in_progress",
  },
  PICKING_PARKED: {
    value: 22,
    type2: "Picking Paused",
    type: "Picking Parked",
    color: "#f368e0",
    slug: "picking_parked",
  },
  PICKED: {
    value: 23,
    type2: "Picking Completed",
    type: "Picked",
    color: "#28a745",
    slug: "picked",
  },
  PACKING_IN_PROGRESS: {
    value: 26,
    type2: "Packing in progress",
    type: "Packing in progress",
    color: "#c0592b",
    slug: "packing_in_progress",
  },
  PACKED: {
    value: 30,
    type2: "Packed",
    type: "Packed",
    color: "#c0592b",
    slug: "packed",
  },
  EXECUTED: {
    value: 40,
    type2: "Executed",
    type: "Executed",
    color: "#95a5a6",
    slug: "executed",
  },
  COMPLETED: {
    value: 50,
    type2: "Completed",
    type: "Completed",
    color: "#c0392b",
    slug: "completed",
  },
  RETURNED: {
    value: 60,
    type2: "Returned",
    dashboardType: "Returns",
    type: "Returned",
    color: "#95a5a6",
    slug: "returned",
  },
  PARTIALLY_RETURNED: {
    value: 55,
    type2: "Partially Returned",
    dashboardType: "Partially Returns",
    type: "Partially Returned",
    color: "#95a5a6",
    slug: "returned",
  },
  PARKED: {
    value: 70,
    type2: "Parked",
    type: "Parked",
    color: "#f1c40f",
    slug: "parked",
  },
  DISCARDED: {
    value: 80,
    type2: "Discarded",
    type: "Discarded",
    color: "#2980b9",
    slug: "discarded",
  },
  VOID: {
    value: 90,
    type2: "Void",
    type: "Void",
    color: "#2980b9",
    slug: "void",
  },
};

export const AGREE_TYPE_SALE = {
  Yes: {
    text: "Yes",
    value: "Y",
  },
  No: {
    text: "No",
    value: "N",
  },
};
export const productSearchLocal = JSON.parse(localStorage.getItem("shop_search_filters") || '{}');
export const productSearchLocalBrands = (() => {
  try {
      return JSON.parse(localStorage.getItem("brandsPreserve")) || {};
  } catch {
      return null;
  }
})();
export const searchFilter = {
  search_key: null,
  tag_ids: null,
  category_ids: productSearchLocal?.search_filter?.category_ids || null,
  brand_ids: productSearchLocalBrands ? productSearchLocalBrands : null,
  is_featured: productSearchLocal?.search_filter?.is_featured || null,
  is_hot_seller: productSearchLocal?.search_filter?.is_hot_seller || null,
  is_new_arrival: productSearchLocal?.search_filter?.is_new_arrival || null,
  tobacco_status: 1,
  sort_by: "created_at:asc",
  searchKeyForShopPage:"",
};

export const setSearchTagKeys = {
  page_size: 15,
  sort_by: "name:asc"
};

export const DISCOUNT_TYPE = {
  PERCENTAGE: 1,
  FIXED: 2,
};

export const taxType = {
  1: "%",
  2: "$",
};

export const ACTIVITY_TYPE = {
  SALE_ORDER: {
    value: 1,
    type: "Sale Order",
  },
  SALE_RETURN: {
    value: 2,
    type: "Sale Return",
  },
  PAYMENT: {
    value: 3,
    type: "Payment",
  },
  REFUND: {
    value: 4,
    type: "Refund",
  },
  STARTING: {
    value: 5,
    type: "Starting",
  },
  SALE_ORDER_VOID: {
    value: 6,
    type: "Sale order void",
  },
  SALE_RETURN_VOID: {
    value: 7,
    type: "Sale return void",
  },
  ACCOUNT_RECHARGE: {
    value: 8,
    type: " Account Recharge",
  },
  BALANCE_ADJUSTMENT: {
    value: 9,
    type: " Balance Adjustment",
  },
  PAYMENT_VOID: {
    value: 10,
    type: " Payment Void",
  },
  SALE_ORDER_EDIT: {
    value: 11,
    type: " Sale Order Edit",
  }
};

export const MODE_OF_PAYMENTS = {
  CASH: {
    value: 1,
    type: "Cash",
  },
  CHEQUE: {
    value: 2,
    type: "Cheque",
  },
  CREDIT_DEBIT: {
    value: 3,
    type: "Credit Debit",
  },
  WIRE_TRANSFER: {
    value: 5,
    type: "Zelle Wire Transfer",
  },
  BANK_TRANSFER: {
    value: 7,
    type: "Money Order",
  },
  GRAIL_PAY: {
    value: 12,
    type: "ACH",
  },
};

export const defaultImageUrl =
  "https://khub-app.s3-accelerate.amazonaws.com/media/product/images/product-coming-soon.png";
export const saleBadgeFour =
"https://khub-app.s3.us-west-2.amazonaws.com/media/banner/images/promotion-banner4.png";
export const saleBadgeThree =
"https://khub-app.s3.us-west-2.amazonaws.com/media/banner/images/promotion-banner3.png";
export const defaultLogo = "https://yourlogo.com"
export const ProductVariantDisplayType = {
  IMAGE: {
    name: "image",
    type: 1,
  },
  RECTANGLE_LIST: {
    name: "rectangle list",
    type: 2,
  },
  RADIO_BUTTON: {
    name: "radio button",
    type: 3,
  },
  DROP_DOWN: {
    name: "drop down",
    type: 4,
  },
  DETAILED_LIST: {
    name: "detailed list",
    type: 5,
  },
  GRID_VIEW: {
    name: "grid view",
    type: 6,
  },
};
export const AGING_TYPE = {
  DueImmediately: {
    // defaultSelect: true,
    name: "Due Immediately",
    value: 0,
  },
  Days7: {
    name: "7 Days",
    value: 7,
  },
  Days15: {
    name: "15 Days",
    value: 15,
  },
  Days30: {
    name: "30 Days",
    value: 30,
  },
  Custom: {
    name: "Custom",
    value: -1,
  },
};
export const PAYMENT_STATUS = {
  PENDING: {
    value: 1,
    name: "Pending",
    bgcolor: "#EFD138",
    color: "#EFD138",
  },
  PAID: {
    value: 2,
    name: "Paid",
    bgcolor: "#EAF4F0",
    color: "#10B265",
  },
  FULLY_REFUNDED: {
    value: 3,
    name: "Fully Refunded",
    bgcolor: "#EAF4F0",
    color: "#10B265",
  },
  PARTIALLY_REFUNDED: {
    value: 4,
    name: "Partially Refunded",
    bgcolor: "#E6ECFD",
    color: "#0646E9",
  },
  VOID: {
    value: 5,
    name: "Void",
    bgcolor: "#FFE7E5",
    color: "#FB1100",
  },
  FAILED: {
    value: 6,
    name: "Failed",
    bgcolor: "#FFE7E5",
    color: "#FB1100",
  },
  UNKNOWN: {
    value: 7,
    name: "N/A",
    bgcolor: "#F2F2F2",
    color: "#333333",
  },
};

export const SHIPPING_TYPE = {
  PICKUP: {
    value: 1,
    type: "Pickup",
  },
  DELIVERY: {
    value: 2,
    type: "Delivery/Shipping",
  },
};

export const ORDER_TYPE = {
  WALK_IN: {
    value: 1,
    type: "Walk In",
  },
  PHONE: {
    value: 2,
    type: "Phone",
  },
  ONLINE: {
    value: 3,
    type: "Online",
  },
  OFFSITE: {
    value: 4,
    type: "Offsite",
  },
};

export const videoTypes = {
  VIDEO_LINK: {
    value: 5,
  },
  VIDEO: {
    value: 4,
  },
};

export const paymentType = {
  CASH: {
    name: "Cash",
    value: 1,
  },
  COD_OA: {
    name: "Cash On Delivery / On Account",
    value: 6,
  },
  ACH_PAYMENT: {
    name: "ACH Payment",
    value: 12,
  },
  CREDIT_DEBIT_CARD: {
    name: "Credit/Debit Card",
    value: 3,
  },
  CHEQUE: {
    name: "Cheque",
    value: 2,
  },
};
export const paymentMethods = {
  NMI: {
    name: "NMI",
    value: 1,
    img: "/assets/img/nmi.jpg",
  },
};
export const saleOrderPayload = {
  pickup_delivery_date: "",
  dispatch_date: "",
  shipping_type: 2,
  reference_number: "",
  update_shipping_billing: true,
  sale_order_details: [],
  shipping_detail: {
    name: "",
    company_name: "",
    telephone_num: "",
    address: "",
    country: "",
    city: "",
    state: "",
    county:"",
    zip_code: "",
    tax_jurisdiction: "",
    is_billing_same: "Y",
    description: "",
  },
  billing_detail: {
    name: "",
    company_name: "",
    telephone_num: "",
    address: "",
    country: "",
    county:"",
    city: "",
    state: "",
    zip_code: "",
    tax_jurisdiction: "",
  },
  payment_detail: {
    payments: [
      {
        amount: "",
        payment_type: 6,
      },
    ],
    // payment_date: "2022-08-10",
  },

  // additional_attributes: {},
};
export const PAYMENT_CATEGORY = {
  SALE_RECEIPT: {
    value: 1,
    type2: "Payment Received",
    type: "Payment Received",
  },
  SALE_RECEIPT_RETURN: {
    value: 2,
    type2: "Receipt Returned",
    type: "Receipt Returned",
  },
  PURCHASE_PAYMENT: {
    value: 3,
    type2: "Paid",
    type: "Paid",
  },
  PURCHASE_PAYMENT_RETURN: {
    value: 4,
    type2: "Payment Returned",
    type: "Payment Returned",
  },
  MUTUAL_PAYMENT: {
    value: 5,
    type2: "Mutual Payment",
    type: "Mutual Payment",
  },
};
export const PAYMENT_TYPE = [
  {
    value: 3,
    type: "Credit/Debit Card",
    type2: "Credit/Debit Card",
    id: "control_03",
    name: "CREDIT_DEBIT_CARD",
    active: true,
  },
  {
    value: 1,
    type: "Cash",
    type2: "Cash",
    id: "control_01",
    name: "CASH",
    active: true,
  },
  {
    value: 2,
    type: "Cheque",
    type2: "Cheque",
    id: "control_02",
    name: "CHEQUE",
    active: true,
  },
  {
    value: 4,
    type2: "Cash On Delivery",
    type: "Cash On Delivery",
    id: "control_04",
    name: "CASH_ON_DELIVERY",
    active: true,
  },
  {
    value: 5,
    type2: "Zelle Wire Transfer",
    type: "Zelle Wire Transfer",
    id: "control_05",
    name: "ZELLE_WIRE_TRANSFER",
    active: true,
  },
  {
    value: 6,
    type2: "On Account",
    type: "On Account",
    id: "control_06",
    name: "ON_ACCOUNT",
    active: true,
  },
  {
    value: 7,
    type2: "Money Order",
    type: "Money Order",
    id: "control_07",
    name: "MONEY_ORDER",
    active: true,
  },
  {
    value: 8,
    type2: "Customer Credit Used",
    type: "Customer Credit Used",
    id: "control_08",
    name: "CUSTOMER_CREDIT_USED",
    active: true,
  },
  {
    value: 9,
    type2: "Customer Credit Added",
    type: "Customer Credit Added",
    id: "control_09",
    name: "CUSTOMER_CREDIT_ADDED",
    active: true,
  },
  {
    value: 12,
    type2: "ACH",
    type: "ACH",
    id: "control_10",
    name: "ACH",
    active: true,
  },
];
export const PAYMENT_TYPE_SALE_ORDER = {
  CASH: {
    value: 1,
    type: "Cash",
    id: "control_01",
  },
  CHEQUE: {
    value: 2,
    type: "Cheque",
    id: "control_02",
  },
  CREDIT_DEBIT_CARD: {
    value: 3,
    type: "Credit/Debit Card",
    id: "control_03",
  },
  CASH_ON_DELIVERY: {
    value: 4,
    type: "Cash On Delivery",
    id: "control_04",
  },
  ON_ACCOUNT: {
    value: 6,
    type: "On Account",
    id: "control_06",
  },
  BANK_TRANSFER_IBFT: {
    value: 7,
    type: "Money Order",
    id: "control_07",
  },
  CUSTOMER_CREDIT_USED: {
    value: 8,
    type: "Customer Credit Used",
    id: "control_08",
  },
  CUSTOMER_CREDIT_ADDED: {
    value: 9,
    type: "Customer Credit Added",
    id: "control_09",
  },
  ACH: {
    value: 12,
    type: "Ach",
    id: "control_10",
  },
};
export const updateCustomer = {
  name: "",
  business_name: "",
  email: "",
  phone_no: null,
  dba_name: "",
  business_phone_no: null,
  business_tax_no: "",
  business_tax_expiry_date: "",
  tax_exempt_code: "",
  old_password: "",
  new_password: "",
  change_password: false,
  additional_attributes: {},
  beverage_license: null,
  beverage_license_expiry: null,
  beverage_license_num: null,
  resale_certificate: null,
  resale_certificate_expiry: null,
  resale_certificate_num: null,
  tobacco_license: null,
  tobacco_license_expiry: null,
  tobacco_license_num: null,
  business_license: null,
  business_license_expiry: null,
  business_license_num: null,
  business_certificate_num: null,
  business_certificate_expiry: null,
  business_certificate: null,
  state_id_license_num: null,
  state_id_license_expiry: null,
  state_id_license: null,
  customer_other_licenses: [],
  customer_shipping_details: {
    name: "",
    company_name: "",
    telephone_num: null,
    address: "",
    country: "",
    city: "",
    state: "",
    zip_code: "",
    tax_jurisdiction: "United State",
    is_billing_same: "Y",
  },
  customer_billing_details: {
    name: "",
    company_name: "",
    telephone_num: null,
    address: "",
    country: "",
    city: "",
    state: "",
    zip_code: "",
    tax_jurisdiction: "United State",
  },
};

export const calculateCart = {
  sale_order_details: [
    {
      product_id: 1,
      qty: 1,
      unit_price: 19,
    },
  ],
};

export const profileNavigation = {
  MY_ACCOUNT: `/profile/my-account`,
  ORDER_HISTORY: `/profile/order-history`,
  STATEMENTS: `/profile/statements`,
  CUSTOMER_LEDGER: `/profile/statements/customer-ledger`,
  PENDING_INVOICES: `/profile/statements/pending-invoices`,
  TRANSACTION_HISTORY: `/profile/statements/transaction-history`,
  SALE_HISTORY: `/profile/statements/sale-history`,
  SALE_RETURN: `/profile/statements/sale-return`,
  WISHLIST: `/profile/wishlist`,
  ACCOUNT_INFO: `/profile/my-account/acc-info`,
  BUSINESS_INFO: `/profile/my-account/bus-info`,
  PAYMENT_INFO: `/profile/my-account/pay-info`,
  SHIPPING_INFO: `/profile/my-account/ship-info`,
  LICENSES: `/profile/my-account/licenses`,
};

export const HEADERSANDKEYS = {
  customerLedger: {
    activity_date: "Activity Date",
    activity_type: "Activity Type",
    details: "Details",
    mode_of_payment: "Mode of Payments",
    debited_amount: "Debit Amount",
    credited_amount: "Credit Amount",
    remaining_balance: "Balance Remaining",
  },
  pendingInvoice: {
    no: "Order No",
    order_date: "Order Date",
    invoice_no: "Sale Order Invoice",
    invoice_aging: "Invoice Aging",
    due_date: "Invoice Due Date",
    total_price: "Invoice Amount",
    amount_paid: "Amount Paid",
    remaining_amount: "Remaining Amount",
  },
  transactionHistory: {
    sale_order_no: "Order No",
    order_date: "Order Date",
    payment_type: "Payment Type",
    invoice_no: "Payment Invoice Number",
    amount: "Paid Amount",
    payment_date: "Payment Date",
    payment_status: "Status",
  },
  saleHistory: {
    no: "Order No",
    order_date: "Order Date",
    total_price: "Invoice Total",
  },
  saleReturn: {
    no: "Order No",
    order_date: "Order Date",
    total_price: "Invoice Total",
  },
};

export const COUNTRIES = {
  Afghanistan: { iso2: "af", phone_code: "93" },
  "Aland Islands": { iso2: "ax", phone_code: "+358-18" },
  Albania: { iso2: "al", phone_code: "355" },
  Algeria: { iso2: "dz", phone_code: "213" },
  "American Samoa": { iso2: "as", phone_code: "+1-684" },
  Andorra: { iso2: "ad", phone_code: "376" },
  Angola: { iso2: "ao", phone_code: "244" },
  Anguilla: { iso2: "ai", phone_code: "+1-264" },
  Antarctica: { iso2: "aq", phone_code: "672" },
  "Antigua And Barbuda": { iso2: "ag", phone_code: "+1-268" },
  Argentina: { iso2: "ar", phone_code: "54" },
  Armenia: { iso2: "am", phone_code: "374" },
  Aruba: { iso2: "aw", phone_code: "297" },
  Australia: { iso2: "au", phone_code: "61" },
  Austria: { iso2: "at", phone_code: "43" },
  Azerbaijan: { iso2: "az", phone_code: "994" },
  Bahrain: { iso2: "bh", phone_code: "973" },
  Bangladesh: { iso2: "bd", phone_code: "880" },
  Barbados: { iso2: "bb", phone_code: "+1-246" },
  Belarus: { iso2: "by", phone_code: "375" },
  Belgium: { iso2: "be", phone_code: "32" },
  Belize: { iso2: "bz", phone_code: "501" },
  Benin: { iso2: "bj", phone_code: "229" },
  Bermuda: { iso2: "bm", phone_code: "+1-441" },
  Bhutan: { iso2: "bt", phone_code: "975" },
  Bolivia: { iso2: "bo", phone_code: "591" },
  "Bonaire, Sint Eustatius and Saba": { iso2: "bq", phone_code: "599" },
  "Bosnia and Herzegovina": { iso2: "ba", phone_code: "387" },
  Botswana: { iso2: "bw", phone_code: "267" },
  "Bouvet Island": { iso2: "bv", phone_code: "0055" },
  Brazil: { iso2: "br", phone_code: "55" },
  "British Indian Ocean Territory": { iso2: "io", phone_code: "246" },
  Brunei: { iso2: "bn", phone_code: "673" },
  Bulgaria: { iso2: "bg", phone_code: "359" },
  "Burkina Faso": { iso2: "bf", phone_code: "226" },
  Burundi: { iso2: "bi", phone_code: "257" },
  Cambodia: { iso2: "kh", phone_code: "855" },
  Cameroon: { iso2: "cm", phone_code: "237" },
  Canada: { iso2: "ca", phone_code: "1" },
  "Cape Verde": { iso2: "cv", phone_code: "238" },
  "Cayman Islands": { iso2: "ky", phone_code: "+1-345" },
  "Central African Republic": { iso2: "cf", phone_code: "236" },
  Chad: { iso2: "td", phone_code: "235" },
  Chile: { iso2: "cl", phone_code: "56" },
  China: { iso2: "cn", phone_code: "86" },
  "Christmas Island": { iso2: "cx", phone_code: "61" },
  "Cocos (Keeling) Islands": { iso2: "cc", phone_code: "61" },
  Colombia: { iso2: "co", phone_code: "57" },
  Comoros: { iso2: "km", phone_code: "269" },
  Congo: { iso2: "cg", phone_code: "242" },
  "Cook Islands": { iso2: "ck", phone_code: "682" },
  "Costa Rica": { iso2: "cr", phone_code: "506" },
  "Cote D'Ivoire (Ivory Coast)": { iso2: "ci", phone_code: "225" },
  Croatia: { iso2: "hr", phone_code: "385" },
  Cuba: { iso2: "cu", phone_code: "53" },
  Curaçao: { iso2: "cw", phone_code: "599" },
  Cyprus: { iso2: "cy", phone_code: "357" },
  "Czech Republic": { iso2: "cz", phone_code: "420" },
  "Democratic Republic of the Congo": { iso2: "cd", phone_code: "243" },
  Denmark: { iso2: "dk", phone_code: "45" },
  Djibouti: { iso2: "dj", phone_code: "253" },
  Dominica: { iso2: "dm", phone_code: "+1-767" },
  "Dominican Republic": { iso2: "do", phone_code: "+1-809 and 1-829" },
  "East Timor": { iso2: "tl", phone_code: "670" },
  Ecuador: { iso2: "ec", phone_code: "593" },
  Egypt: { iso2: "eg", phone_code: "20" },
  "El Salvador": { iso2: "sv", phone_code: "503" },
  "Equatorial Guinea": { iso2: "gq", phone_code: "240" },
  Eritrea: { iso2: "er", phone_code: "291" },
  Estonia: { iso2: "ee", phone_code: "372" },
  Ethiopia: { iso2: "et", phone_code: "251" },
  "Falkland Islands": { iso2: "fk", phone_code: "500" },
  "Faroe Islands": { iso2: "fo", phone_code: "298" },
  "Fiji Islands": { iso2: "fj", phone_code: "679" },
  Finland: { iso2: "fi", phone_code: "358" },
  France: { iso2: "fr", phone_code: "33" },
  "French Guiana": { iso2: "gf", phone_code: "594" },
  "French Polynesia": { iso2: "pf", phone_code: "689" },
  "French Southern Territories": { iso2: "tf", phone_code: "262" },
  Gabon: { iso2: "ga", phone_code: "241" },
  "Gambia The": { iso2: "gm", phone_code: "220" },
  Georgia: { iso2: "ge", phone_code: "995" },
  Germany: { iso2: "de", phone_code: "49" },
  Ghana: { iso2: "gh", phone_code: "233" },
  Gibraltar: { iso2: "gi", phone_code: "350" },
  Greece: { iso2: "gr", phone_code: "30" },
  Greenland: { iso2: "gl", phone_code: "299" },
  Grenada: { iso2: "gd", phone_code: "+1-473" },
  Guadeloupe: { iso2: "gp", phone_code: "590" },
  Guam: { iso2: "gu", phone_code: "+1-671" },
  Guatemala: { iso2: "gt", phone_code: "502" },
  "Guernsey and Alderney": { iso2: "gg", phone_code: "+44-1481" },
  Guinea: { iso2: "gn", phone_code: "224" },
  "Guinea-Bissau": { iso2: "gw", phone_code: "245" },
  Guyana: { iso2: "gy", phone_code: "592" },
  Haiti: { iso2: "ht", phone_code: "509" },
  "Heard Island and McDonald Islands": { iso2: "hm", phone_code: "672" },
  Honduras: { iso2: "hn", phone_code: "504" },
  "Hong Kong S.A.R.": { iso2: "hk", phone_code: "852" },
  Hungary: { iso2: "hu", phone_code: "36" },
  Iceland: { iso2: "is", phone_code: "354" },
  India: { iso2: "in", phone_code: "91" },
  Indonesia: { iso2: "id", phone_code: "62" },
  Iran: { iso2: "ir", phone_code: "98" },
  Iraq: { iso2: "iq", phone_code: "964" },
  Ireland: { iso2: "ie", phone_code: "353" },
  Israel: { iso2: "il", phone_code: "972" },
  Italy: { iso2: "it", phone_code: "39" },
  Jamaica: { iso2: "jm", phone_code: "+1-876" },
  Japan: { iso2: "jp", phone_code: "81" },
  Jersey: { iso2: "je", phone_code: "+44-1534" },
  Jordan: { iso2: "jo", phone_code: "962" },
  Kazakhstan: { iso2: "kz", phone_code: "7" },
  Kenya: { iso2: "ke", phone_code: "254" },
  Kiribati: { iso2: "ki", phone_code: "686" },
  Kosovo: { iso2: "xk", phone_code: "383" },
  Kuwait: { iso2: "kw", phone_code: "965" },
  Kyrgyzstan: { iso2: "kg", phone_code: "996" },
  Laos: { iso2: "la", phone_code: "856" },
  Latvia: { iso2: "lv", phone_code: "371" },
  Lebanon: { iso2: "lb", phone_code: "961" },
  Lesotho: { iso2: "ls", phone_code: "266" },
  Liberia: { iso2: "lr", phone_code: "231" },
  Libya: { iso2: "ly", phone_code: "218" },
  Liechtenstein: { iso2: "li", phone_code: "423" },
  Lithuania: { iso2: "lt", phone_code: "370" },
  Luxembourg: { iso2: "lu", phone_code: "352" },
  "Macau S.A.R.": { iso2: "mo", phone_code: "853" },
  Macedonia: { iso2: "mk", phone_code: "389" },
  Madagascar: { iso2: "mg", phone_code: "261" },
  Malawi: { iso2: "mw", phone_code: "265" },
  Malaysia: { iso2: "my", phone_code: "60" },
  Maldives: { iso2: "mv", phone_code: "960" },
  Mali: { iso2: "ml", phone_code: "223" },
  Malta: { iso2: "mt", phone_code: "356" },
  "Man (Isle of)": { iso2: "im", phone_code: "+44-1624" },
  "Marshall Islands": { iso2: "mh", phone_code: "692" },
  Martinique: { iso2: "mq", phone_code: "596" },
  Mauritania: { iso2: "mr", phone_code: "222" },
  Mauritius: { iso2: "mu", phone_code: "230" },
  Mayotte: { iso2: "yt", phone_code: "262" },
  Mexico: { iso2: "mx", phone_code: "52" },
  Micronesia: { iso2: "fm", phone_code: "691" },
  Moldova: { iso2: "md", phone_code: "373" },
  Monaco: { iso2: "mc", phone_code: "377" },
  Mongolia: { iso2: "mn", phone_code: "976" },
  Montenegro: { iso2: "me", phone_code: "382" },
  Montserrat: { iso2: "ms", phone_code: "+1-664" },
  Morocco: { iso2: "ma", phone_code: "212" },
  Mozambique: { iso2: "mz", phone_code: "258" },
  Myanmar: { iso2: "mm", phone_code: "95" },
  Namibia: { iso2: "na", phone_code: "264" },
  Nauru: { iso2: "nr", phone_code: "674" },
  Nepal: { iso2: "np", phone_code: "977" },
  Netherlands: { iso2: "nl", phone_code: "31" },
  "New Caledonia": { iso2: "nc", phone_code: "687" },
  "New Zealand": { iso2: "nz", phone_code: "64" },
  Nicaragua: { iso2: "ni", phone_code: "505" },
  Niger: { iso2: "ne", phone_code: "227" },
  Nigeria: { iso2: "ng", phone_code: "234" },
  Niue: { iso2: "nu", phone_code: "683" },
  "Norfolk Island": { iso2: "nf", phone_code: "672" },
  "North Korea": { iso2: "kp", phone_code: "850" },
  "Northern Mariana Islands": { iso2: "mp", phone_code: "+1-670" },
  Norway: { iso2: "no", phone_code: "47" },
  Oman: { iso2: "om", phone_code: "968" },
  Pakistan: { iso2: "pk", phone_code: "92" },
  Palau: { iso2: "pw", phone_code: "680" },
  "Palestinian Territory Occupied": { iso2: "ps", phone_code: "970" },
  Panama: { iso2: "pa", phone_code: "507" },
  "Papua new Guinea": { iso2: "pg", phone_code: "675" },
  Paraguay: { iso2: "py", phone_code: "595" },
  Peru: { iso2: "pe", phone_code: "51" },
  Philippines: { iso2: "ph", phone_code: "63" },
  "Pitcairn Island": { iso2: "pn", phone_code: "870" },
  Poland: { iso2: "pl", phone_code: "48" },
  Portugal: { iso2: "pt", phone_code: "351" },
  "Puerto Rico": { iso2: "pr", phone_code: "+1-787 and 1-939" },
  Qatar: { iso2: "qa", phone_code: "974" },
  Reunion: { iso2: "re", phone_code: "262" },
  Romania: { iso2: "ro", phone_code: "40" },
  Russia: { iso2: "ru", phone_code: "7" },
  Rwanda: { iso2: "rw", phone_code: "250" },
  "Saint Helena": { iso2: "sh", phone_code: "290" },
  "Saint Kitts And Nevis": { iso2: "kn", phone_code: "+1-869" },
  "Saint Lucia": { iso2: "lc", phone_code: "+1-758" },
  "Saint Pierre and Miquelon": { iso2: "pm", phone_code: "508" },
  "Saint Vincent And The Grenadines": { iso2: "vc", phone_code: "+1-784" },
  "Saint-Barthelemy": { iso2: "bl", phone_code: "590" },
  "Saint-Martin (French part)": { iso2: "mf", phone_code: "590" },
  Samoa: { iso2: "ws", phone_code: "685" },
  "San Marino": { iso2: "sm", phone_code: "378" },
  "Sao Tome and Principe": { iso2: "st", phone_code: "239" },
  "Saudi Arabia": { iso2: "sa", phone_code: "966" },
  Senegal: { iso2: "sn", phone_code: "221" },
  Serbia: { iso2: "rs", phone_code: "381" },
  Seychelles: { iso2: "sc", phone_code: "248" },
  "Sierra Leone": { iso2: "sl", phone_code: "232" },
  Singapore: { iso2: "sg", phone_code: "65" },
  "Sint Maarten (Dutch part)": { iso2: "sx", phone_code: "1721" },
  Slovakia: { iso2: "sk", phone_code: "421" },
  Slovenia: { iso2: "si", phone_code: "386" },
  "Solomon Islands": { iso2: "sb", phone_code: "677" },
  Somalia: { iso2: "so", phone_code: "252" },
  "South Africa": { iso2: "za", phone_code: "27" },
  "South Georgia": { iso2: "gs", phone_code: "500" },
  "South Korea": { iso2: "kr", phone_code: "82" },
  "South Sudan": { iso2: "ss", phone_code: "211" },
  Spain: { iso2: "es", phone_code: "34" },
  "Sri Lanka": { iso2: "lk", phone_code: "94" },
  Sudan: { iso2: "sd", phone_code: "249" },
  Suriname: { iso2: "sr", phone_code: "597" },
  "Svalbard And Jan Mayen Islands": { iso2: "sj", phone_code: "47" },
  Swaziland: { iso2: "sz", phone_code: "268" },
  Sweden: { iso2: "se", phone_code: "46" },
  Switzerland: { iso2: "ch", phone_code: "41" },
  Syria: { iso2: "sy", phone_code: "963" },
  Taiwan: { iso2: "tw", phone_code: "886" },
  Tajikistan: { iso2: "tj", phone_code: "992" },
  Tanzania: { iso2: "tz", phone_code: "255" },
  Thailand: { iso2: "th", phone_code: "66" },
  "The Bahamas": { iso2: "bs", phone_code: "+1-242" },
  Togo: { iso2: "tg", phone_code: "228" },
  Tokelau: { iso2: "tk", phone_code: "690" },
  Tonga: { iso2: "to", phone_code: "676" },
  "Trinidad And Tobago": { iso2: "tt", phone_code: "+1-868" },
  Tunisia: { iso2: "tn", phone_code: "216" },
  Turkey: { iso2: "tr", phone_code: "90" },
  Turkmenistan: { iso2: "tm", phone_code: "993" },
  "Turks And Caicos Islands": { iso2: "tc", phone_code: "+1-649" },
  Tuvalu: { iso2: "tv", phone_code: "688" },
  Uganda: { iso2: "ug", phone_code: "256" },
  Ukraine: { iso2: "ua", phone_code: "380" },
  "United Arab Emirates": { iso2: "ae", phone_code: "971" },
  "United Kingdom": { iso2: "gb", phone_code: "44" },
  "United States": { iso2: "us", phone_code: "1" },
  "United States Minor Outlying Islands": { iso2: "um", phone_code: "1" },
  Uruguay: { iso2: "uy", phone_code: "598" },
  Uzbekistan: { iso2: "uz", phone_code: "998" },
  Vanuatu: { iso2: "vu", phone_code: "678" },
  "Vatican City State (Holy See)": { iso2: "va", phone_code: "379" },
  Venezuela: { iso2: "ve", phone_code: "58" },
  Vietnam: { iso2: "vn", phone_code: "84" },
  "Virgin Islands (British)": { iso2: "vg", phone_code: "+1-284" },
  "Virgin Islands (US)": { iso2: "vi", phone_code: "+1-340" },
  "Wallis And Futuna Islands": { iso2: "wf", phone_code: "681" },
  "Western Sahara": { iso2: "eh", phone_code: "212" },
  Yemen: { iso2: "ye", phone_code: "967" },
  Zambia: { iso2: "zm", phone_code: "260" },
  Zimbabwe: { iso2: "zw", phone_code: "263" },
};

// Order's Invoice Statuses
// export const INVOICE_STATUS = {
//   DUE: {
//     value: 1,
//     name: "Due",
//   },
//   OVER_DUE: {
//     value: 2,
//     name: "Over_Due",
//   },
//   PAID: {
//     value: 3,
//     name: "Paid",
//   },
//   OVER_PAID: {
//     value: 4,
//     name: "Over_Paid",
//   },
// };

export const INVOICE_STATUS = {
  DUE: {
    value: 1,
    name: "Due",
    bgcolor: "#ffbbbb",
    // color: "#0646E9",
  },
  UN_PAID: {
    value: 2,
    name: "Un Paid",
    bgcolor: "#f5dcdc",
    // color: "#0646E9",
  },
  PAID: {
    value: 3,
    name: "Paid",
    bgcolor: "#c7ecc7",
    // color: "#10B265",
  },
  OVER_PAID: {
    value: 4,
    name: "Over Paid",
    bgcolor: "#d1f0ff",
    // color: "#0646E9",
  },
  PARTIALLY_PAID: {
    value: 5,
    name: "Partially Paid",
    bgcolor: "#ffd699",
    // color: "#0646E9",
  },
  VOID: {
    value: 8,
    name: "Void",
    bgcolor: "#f5dcdc",
    // color: "#FB1100",
  },
};
// Customer Signup fields

export const class_of_trades = [
  { name: "Retailer", value: "Retailer" },
  {
    name: "Distributor",
    value: "Distributor",
  },
  { name: "Other", value: "other" },
];

export const CLASS_OF_TRADE_OBJ = {
  RETAILER: { name: "Retailer", value: "Retailer" },
  DISTRIBUTOR: {
    name: "Distributor",
    value: "Distributor",
  },
  OTHER: { name: "Other", value: "Other" },
};

export const shipping_billing_address_confirmation = [
  { name: "Yes", value: "Y" },
  { name: "No", value: "N" },
];
export const promotion_of_acceptance = [
  {
    name: "Yes",
    value: 1,
  },
  { name: "No", value: 0 },
];
export const tiers = [
  {
    name: "Tier 1",
    value: 1,
  },
  {
    name: "Tier 2",
    value: 2,
  },
  {
    name: "Tier 3",
    value: 3,
  },
  {
    name: "Tier 4",
    value: 4,
  },
  {
    name: "Tier 5",
    value: 5,
  },
];

export const USA_STATES = [
  { label: "Alabama", value: "AL" },
  { label: "Alaska", value: "AK" },
  { label: "American Samoa", value: "AS" },
  { label: "Arizona", value: "AZ" },
  { label: "Arkansas", value: "AR" },
  { label: "California", value: "CA" },
  { label: "Colorado", value: "CO" },
  { label: "Connecticut", value: "CT" },
  { label: "Delaware", value: "DE" },
  { label: "District Of Columbia", value: "DC" },
  { label: "Federated States Of Micronesia", value: "FM" },
  { label: "Florida", value: "FL" },
  { label: "Georgia", value: "GA" },
  { label: "Guam", value: "GU" },
  { label: "Hawaii", value: "HI" },
  { label: "Idaho", value: "ID" },
  { label: "Illinois", value: "IL" },
  { label: "Indiana", value: "IN" },
  { label: "Iowa", value: "IA" },
  { label: "Kansas", value: "KS" },
  { label: "Kentucky", value: "KY" },
  { label: "Louisiana", value: "LA" },
  { label: "Maine", value: "ME" },
  { label: "Marshall Islands", value: "MH" },
  { label: "Maryland", value: "MD" },
  { label: "Massachusetts", value: "MA" },
  { label: "Michigan", value: "MI" },
  { label: "Minnesota", value: "MN" },
  { label: "Mississippi", value: "MS" },
  { label: "Missouri", value: "MO" },
  { label: "Montana", value: "MT" },
  { label: "Nebraska", value: "NE" },
  { label: "Nevada", value: "NV" },
  { label: "New Hampshire", value: "NH" },
  { label: "New Jersey", value: "NJ" },
  { label: "New Mexico", value: "NM" },
  { label: "New York", value: "NY" },
  { label: "North Carolina", value: "NC" },
  { label: "North Dakota", value: "ND" },
  { label: "Northern Mariana Islands", value: "MP" },
  { label: "Ohio", value: "OH" },
  { label: "Oklahoma", value: "OK" },
  { label: "Oregon", value: "OR" },
  { label: "Palau", value: "PW" },
  { label: "Pennsylvania", value: "PA" },
  { label: "Puerto Rico", value: "PR" },
  { label: "Rhode Island", value: "RI" },
  { label: "South Carolina", value: "SC" },
  { label: "South Dakota", value: "SD" },
  { label: "Tennessee", value: "TN" },
  { label: "Texas", value: "TX" },
  { label: "Utah", value: "UT" },
  { label: "Vermont", value: "VT" },
  { label: "Virgin Islands", value: "VI" },
  { label: "Virginia", value: "VA" },
  { label: "Washington", value: "WA" },
  { label: "West Virginia", value: "WV" },
  { label: "Wisconsin", value: "WI" },
  { label: "Wyoming", value: "WY" },
];
export const CELERY_PROGRESS_STATUS = {
  1: {
    id: 1,
    slug: "PUBLISHED",
    name: "Published",
  },
  2: {
    id: 2,
    slug: "STARTED",
    name: "Started",
  },
  3: {
    id: 3,
    slug: "COMPLETED",
    name: "Completed",
  },
  4: {
    id: 4,
    slug: "FAILED",
    name: "Failed",
  },
  5: {
    id: 5,
    slug: "PROGRESS",
    name: "Progress",
  },
};

export const CELERY_PROGRESS_STATUS_OBJECT = {
  PUBLISHED: {
    id: 1,
    slug: "PUBLISHED",
    name: "Published",
  },
  STARTED: {
    id: 2,
    slug: "STARTED",
    name: "Started",
  },
  COMPLETED: {
    id: 3,
    slug: "COMPLETED",
    name: "Success",
  },
  FAILED: {
    id: 4,
    slug: "FAILED",
    name: "Failure",
  },
  PROGRESS: {
    id: 5,
    slug: "PROGRESS",
    name: "In Progress",
  },
};

export const EXPORT_FILE_TYPE = {
  CSV: 1,
  PDF: 2,
  EXCEL: 3,
};

export const CHECKOUT_TERMS_AND_CONDITION_TEXT = `By Proceeding further, you acknowledge that you have read the Terms and Conditions of the Business owner of this E-Commerce Site, Kommerce Hub Platform and affiliated Payment processors. If the payment is being made via ACH, you confirm you authorize the Business Owner to perform ACH debits or credits as per this authorization that you are agreeing to and also confirm that you have read the terms of GrailPay who is the affiliated payment processor of Kommerce Hub.`;
export const CHECKOUT_TERMS_AND_CONDITION_TEXT_2 = () => {
  return (
    <>
      Click here to indicate that you have read and agree to the{" "}
      <Link to="/terms-conditions">
        <b>Terms and Conditions</b>
      </Link>{" "}
      of KHUB Policy
    </>
  );
};

export function convertToSlug(text) {
  return text
      .toLowerCase()   // Convert the string to lowercase letters
      .replace(/\s+/g, '-')  // Replace spaces with hyphens
      .replace(/[^\w-]+/g, '');  // Remove all non-word chars
}

export const DEFAULT_DELIVERY_CHARGES = 200

export const TERMS_AND_CONDITION = "Terms and Conditions";
export const PRIVACY_POLICY = 'Privacy Policy';
export const TERMS_AND_CONDITIONS_AND_PRIVACY_POLICY = "Terms and Conditions & Privacy Policy"
export const searchFilterforQuick = {
  search_key: null,
  tag_ids: null,
  category_ids: null,
  brand_ids: null,
  is_featured:  null,
  is_hot_seller: null,
  is_new_arrival: null,
  tobacco_status: 1,
  sort_by: "created_at:asc",
  searchKeyForShopPage:"",
};

export const counterDuration = {
    ALL_THE_TIME: 1,
    LAST_24_HR: 2,
    LAST_12_HR: 3,
}

export const capitalizeWords = (arr) => {
  if (!Array.isArray(arr) || arr.length === 0) return [];
  return arr.map((str) => {
    if (!str) return "";
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(" ");
  });
};