import React, { useState, useMemo } from 'react';
import Categories from './components/categories';
import Profile from './components/profile';
import Cart from './components/cart';
import Search from './components/search';
import { NavLink, Link } from "react-router-dom";
import MobileNavigation from './components/mobileNavigation';

const HeaderTemplate3 = ({ settingData, themeSettingData, categoryTreeData, currentState, profileItems, cartData, currency, brandsData }) => {
  const [isActionsVisible, setIsActionsVisible] = useState(false);

  const toggleActionsVisibility = () => {
    setIsActionsVisible((prev) => !prev);
  };

  return (
    <header className="header-tmpl">
      <nav aria-label="Global">
        <div className="tw-flex">
          <button
            type="button"
            onClick={toggleActionsVisibility}
            className="-tw-m-2.5 tw-inline-flex tw-items-center tw-justify-center tw-rounded-md tw-p-2.5 tw-text-gray-700"
          >
            <span className="tw-sr-only">Open main menu</span>
            <svg className="tw-h-6 tw-w-6 mobile-menu-bar" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
          </button>
        </div>

        {isActionsVisible && (
          <MobileNavigation
            setIsActionsVisible={setIsActionsVisible}
            categoryTreeData={categoryTreeData}
            settingData={settingData}
            themeSettingData={themeSettingData}
            currentState={currentState}
            profileItems={profileItems}
          />
        )}

        <div class="header-branding">
          <Link to={process.env.PUBLIC_URL}>
            <span className="tw-sr-only">{settingData.ecom_title}</span>
            <img src={themeSettingData.logo} alt={settingData.ecom_title} />
          </Link>
        </div>

        <div className="header-actions !tw-basis-auto">
          <div className="action-block">
            <NavLink
              className="action-item mobile-hide-header"
              to={process.env.PUBLIC_URL + "/quick-order"}
              activeClassName="active"
            >
              Quick Order
            </NavLink>
          </div>

          <Search
            settingData={settingData}
            themeSettingData={themeSettingData}
            currency={currency}
            isIcon={true}
            parentClassName={'action-block icon-search !tw-basis-auto max-lg:!tw-hidden'}
          />

          <Cart cartData={cartData} />
          <Profile currentState={currentState} profileItems={profileItems} />
        </div>
      </nav>
      
      <Categories categoryTreeData={categoryTreeData} brandsData={brandsData} className={'max-lg:!tw-hidden'} rowsCount={2}/>

      <div className='mobile-search'>
        <Search settingData={settingData} themeSettingData={themeSettingData} currency={currency}/>
      </div>
    </header>
  );
};

export default HeaderTemplate3;
