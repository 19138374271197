// Constant Types Block
import {
  LOGIN_SUCCESS,
  SALE_AGENT_VIA_CUSTOMER_SUCCESS,
  SET_LOGIN_SUCCESS,
  SET_LOGOUT_SUCCESS,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_TOKEN_SUCCESS,
  LOGOUT_WITH_SUCCESS,
  LOGIN_SALE_AGENT_SUCCESS,
  FETCH_ASSIGN_CUSTOMERS_SUCCESS,
  SET_SALE_AGENT_VIA_CUSTOMER_LOADER_SUCCESS,
  REGISTER_CUSTOMER_SUCCESS,
  ONBOARDED,
  CUSTOMER_DATA,
  SET_TOKEN_VIA_IMPERSONATE,
  UPDATE_CUSTOMER_SHIPPING_BILLING
} from "../constant_types";

const initState = {
  user: undefined,
  authToken: undefined,
  refreshToken: undefined,
  saleAgent: undefined,
  saleAgentAuthToken: undefined,
  saleAgentRefreshToken: undefined,
  saleAgentAssignCustomers: undefined,
  saleAgentViaCustomerLoader: false,
  uploading: false,
  setCustomerCartLoader: false,
  logout: false,
  message: {
    success: null,
    error: null,
  },
};

export const userReducer = (state = initState, action) => {
  if (action.type === LOGIN_SUCCESS) {
    return {
      ...state,
      authToken: action.payload.access_token,
      refreshToken: action.payload.refresh_token,
      user: action.payload.customer,
    };
  }
  if (action.type === ONBOARDED) {
    return {
      ...state,
      user: {...state.user,achHas:true},
    };
  }
  if (action.type === REGISTER_CUSTOMER_SUCCESS) {
    return {
      ...state,
    };
  }

  if (action.type === LOGIN_SALE_AGENT_SUCCESS) {
    return {
      ...state,
      saleAgentAuthToken: action.payload.access_token,
      saleAgentRefreshToken: action.payload.refresh_token,
      saleAgent: action.payload.agent,
    };
  }

  if (action.type === SET_LOGIN_SUCCESS) {
    return {
      ...state,
      authToken: action.payload?.access_token,
      refreshToken: action.payload?.refresh_token,
      user: action.payload?.customer,
    };
  }

  if (action.type === CUSTOMER_DATA) {
    return {
      ...state,
      user: action.payload?.customer,
    };
  }

  if (action.type === UPDATE_CUSTOMER_SUCCESS) {
    return {
      ...state,
      user: action.payload,
    };
  }

  if (action.type === UPDATE_CUSTOMER_SHIPPING_BILLING) {
    return {
      ...state,
      user: {...state.user, ...action.payload},
    };
  }

  if (action.type === SET_LOGOUT_SUCCESS) {
    return {
      ...state,
      authToken: undefined,
      refreshToken: undefined,
      user: undefined,
    };
  }

  if (action.type === UPDATE_TOKEN_SUCCESS) {
    return {
      ...state,
      authToken: action.payload.accessToken,
      refreshToken: action.payload.refreshToken,
    };
  }

  if (action.type === LOGOUT_WITH_SUCCESS) {
    return {
      ...state,
      logout: action.payload,
      authToken: undefined,
      refreshToken: undefined,
      user: undefined,
    };
  }

  if (action.type === FETCH_ASSIGN_CUSTOMERS_SUCCESS) {
    return {
      ...state,
      saleAgentAssignCustomers: action.payload,
    };
  }

  if (action.type === SALE_AGENT_VIA_CUSTOMER_SUCCESS) {
    return {
      ...state,
      saleAgent: action.payload.agent,
      authToken: action.payload.access_token,
      refreshToken: action.payload.refresh_token,
      user: action.payload.customer,
    };
  }
  if (action.type === SET_TOKEN_VIA_IMPERSONATE) {
    return {
      ...state,
      authToken: action.payload,
    };
  }
  
  if (action.type === SET_SALE_AGENT_VIA_CUSTOMER_LOADER_SUCCESS) {
    return { ...state, pass: action.payload };
  }

  return state;
};
