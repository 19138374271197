// Cart Constant Types
export const ADD_TO_CART = "ADD_TO_CART";
export const BULK_ADD_TO_CART = "BULK_ADD_TO_CART";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const INCREASE_QUANTITY = "INCREASE_QUANTITY";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const DELETE_ALL_FROM_CART = "DELETE_ALL_FROM_CART";
export const GET_CART_ITEMS = "GET_CART_ITEMS"
export const SET_CART_FROM_API = "SET_CART_FROM_API"


// Order Constant Types
export const FETCH_ALL_ORDER_SUCCESS = "FETCH_ALL_ORDER_SUCCESS";
export const SALE_ORDER_SUCCESS = "SALE_ORDER_SUCCESS";
export const FETCH_STATES_SUCCESS = "FETCH_STATES_SUCCESS";
export const FETCH_BILLING_STATES_SUCCESS = "FETCH_BILLING_STATES_SUCCESS";
export const FETCH_BILLING_CITIES_SUCCESS = "FETCH_BILLING_CITIES_SUCCESS";
export const FETCH_FORM_DATA = "FETCH_FORM_DATA";
export const FETCH_CITIES_SUCCESS = "FETCH_CITIES_SUCCESS";
export const FETCH_SINGLE_ORDER_SUCCESS = "FETCH_SINGLE_ORDER_SUCCESS";
export const FETCH_LOADING_SUCCESS = "FETCH_LOADING_SUCCESS";
export const COPY_ORDER_SUCCESS = "COPY_ORDER_SUCCESS";
export const SET_FORM_DATA = "SET_FORM_DATA";

// Authorizations Constant Types
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const ONBOARDED = "ONBOARDED";
export const LOGOUT_WITH_SUCCESS = "LOGOUT_WITH_SUCCESS";
export const SET_LOGIN_SUCCESS = "SET_LOGIN_SUCCESS";
export const CUSTOMER_DATA = "CUSTOMER_DATA";
export const SET_LOGOUT_SUCCESS = "SET_LOGOUT_SUCCESS";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_SHIPPING_BILLING = "UPDATE_CUSTOMER_SHIPPING_BILLING";
export const UPDATE_TOKEN_SUCCESS = "UPDATE_TOKEN_SUCCESS";
export const LOGIN_SALE_AGENT_SUCCESS = "LOGIN_SALE_AGENT_SUCCESS";
export const FETCH_ASSIGN_CUSTOMERS_SUCCESS = "FETCH_ASSIGN_CUSTOMERS_SUCCESS";
export const SALE_AGENT_VIA_CUSTOMER_SUCCESS =
  "SALE_AGENT_VIA_CUSTOMER_SUCCESS";
export const SET_TOKEN_VIA_IMPERSONATE =
  "SET_TOKEN_VIA_IMPERSONATE";
export const SET_SALE_AGENT_VIA_CUSTOMER_LOADER_SUCCESS =
  "SET_SALE_AGENT_VIA_CUSTOMER_LOADER_SUCCESS";

export const REGISTER_CUSTOMER_SUCCESS = "REGISTER_CUSTOMER_SUCCESS";


// Categories Constant Types
export const FETCH_CATEGORY_TREE = "FETCH_CATEGORY_TREE";

// Product Contant Types
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FETCH_SINGLE_PRODUCT = "FETCH_SINGLE_PRODUCT";
export const FETCH_FEATURED_PRODUCT = "FETCH_FEATURED_PRODUCT";
export const FETCH_HOTSELLER_PRODUCT = "FETCH_HOTSELLER_PRODUCT";
export const FETCH_NEWARRIVALS_PRODUCT = "FETCH_NEWARRIVALS_PRODUCT";
export const FETCH_SUGGESTED_PRODUCT = "FETCH_SUGGESTED_PRODUCT";
export const EMPTY_DETAIL_PRODUCT = "EMPTY_DETAIL_PRODUCT";
export const FETCH_PRODUCT_LOADING_SUCCESS="FETCH_PRODUCT_LOADING_SUCCESS";
export const FETCH_PRODUCTS_FAILURE="FETCH_PRODUCTS_FAILURE";
export const FETCH_PRODUCTS_START="FETCH_PRODUCTS_START";
export const UPDATE_SEARCH_KEY = 'UPDATE_SEARCH_KEY';

//Brands Constants Types
export const FETCH_BRANDS= "FETCH_BRANDS";


// Wishlist Constant Types
export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const DELETE_FROM_WISHLIST = "DELETE_FROM_WISHLIST";
export const DELETE_ALL_FROM_WISHLIST = "DELETE_ALL_FROM_WISHLIST";

// Common Constant Types
export const GET_ALL_COUNTRIES_SUCCESS = "GET_ALL_COUNTRIES";
export const GET_ALL_BILLING_STATES = "GET_ALL_BILLING_STATES";
export const GET_ALL_BILLING_CITIES = "GET_ALL_BILLING_CITIES";
export const GET_ALL_STATES_SUCCESS = "GET_ALL_STATES";
export const GET_ALL_COUNTIES_SUCCESS = "GET_ALL_COUNTIES";
export const GET_ALL_CITIES_SUCCESS = "GET_ALL_CITIES";
export const SET_LOADING = "SET_LOADING";
export const EXPORT_PDF_CSV_LOADING = "EXPORT_PDF_CSV_LOADING";
export const EXPORT_PDF_CSV_STOPPED = "EXPORT_PDF_CSV_STOPPED";
export const GET_ALL_CUSTOM_PAGES = "GET_ALL_CUSTOM_PAGES"
export const GET_SINGLE_CUSTOM_PAGE = "GET_SINGLE_CUSTOM_PAGES_URL"
export const SET_BRAND_LOADING_STATE = "SET_BRAND_LOADING_STATE"



// PDF/CSV Export
export const EXPORT_PDF_CSV = "GET_PDF_CSV"

export const SET_LOADING_STATE = "SET_LOADING_STATE"