import React from "react";

export default function Promotions({ product, classMain, classText }) {
  return (
    <>
  {(product?.selected_unit_price?.is_promotion == true || 
  product?.selected_unit_price?.promotion?.has_sale_badge &&
    product?.variant_product?.find(
      (variant) => variant.selected_unit_price?.promotion?.has_sale_badge
    )) && (
      <div className={classMain}>
        <img
          className={classText}
          src={product.selected_unit_price?.promotion?.sale_badge_template}
          alt=""
        />
      </div>
  )}
</>

  );
}
