// package block
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Button,
  notification,
  Spin,
  Input,
  Select,
  Checkbox,
} from "antd";
import { Formik, Form, Field } from "formik";

// custom components and values

import PhoneInput from "../../../components/form/PhoneInput";
import {
  AccountType,
  EDIT_BANK_SCHEMA,
  ON_BOARDING_CUSTOMER_SCHEMA,
  convertStatementToObject,
  initialValues,
} from "./utils";
import {
  bankDetail,
  consentTenant,
  getCities,
  getOnBoardAch,
  onBoardAch,
  postBankDetail,
} from "../../../redux/paymentCrud";
import { USA_STATES } from "../../constant/Constants";
import { MaskedInput } from "antd-mask-input";
import "./style.css";
import { Input as customInput } from "../../../components/form/Input";
import { Select as CustomSelect } from "../../../components/form/Select";
import { Button as CustomButton } from "../../../components/utils/Button";
import moment from "moment";
import { onboardAch } from "../../../redux/actions/loginActions";

const agreementLink =
  "https://consultstech-my.sharepoint.com/:w:/g/personal/feroze_k_ikhub_biz/EQd3WN1v0vZBuXl0f_E7wucBZphSq8qxYSw4MR68NKsNOw?e=gT1IhI";
export const ConsentSubType = {
  ACH: 1,
  CARD: 2,
};
export const ConsentType = {
  GRAIL_PAY: 1,
  FLEX_PAY: 2,
  NMI: 3,
};
const AchPaymentForm = ({ cb, onHide, setBankInfo, isDisabled }) => {
  const formRef = useRef(null);
  const bankFormRef = useRef(null);
  const dispatch = useDispatch();

  const { Option } = Select;
  const [isBankModalOpen, setIsBankModalOpen] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [loadingBank, setLoadingBank] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAgreementModal, setShowAgreementModal] = useState(false);
  const [allCheck, setAllCheck] = useState({
    ACHAuthCheck: false,
    signedAuth: false,
    customerAuthCheck: false,
  });
  const [editable, setEditable] = useState(true);
  const [locations, setLocations] = useState({ cities: [] });
  const [consentDetail, setConsentDetail] = useState({
    consent_date: moment().format("YYYY-MM-DD"),
    type: ConsentType.GRAIL_PAY,
    sub_type: ConsentSubType.ACH,
    name: "",
  });
  const { cities } = locations;
  const getCitiesData = async (e, country, setCities, setIsLoading) => {
    try {
      setIsLoading(true);
      const response = await getCities({ country: country, state: e });
      const { entity } = response.data;
      setCities(entity);
    } catch (err) {
      // Handle error if needed
    } finally {
      setIsLoading(false);
    }
  };
  const handleStateChange = (value) => {
    const { setFieldValue, values } = formRef.current;
    setFieldValue("address.city", "");
    getCitiesData(
      value,
      values?.address?.country,
      (cities) => {
        setLocations((Prev) => ({ ...Prev, cities: cities }));
      },
      setIsLoading
    );
  };

  const closeModal = () => setIsBankModalOpen(false);
  const _consentTenant = () => {
    consentTenant(consentDetail)
      .then((res) => {
        _hide();
      })
      .catch((err) => {
        err.response.data.errors.map((error) =>
          notification.error({ message: error })
        );
      });
  };
  const getBank = async () => {
    setLoadingBank(true);
    const setValues = formRef?.current?.setValues;
    const _setValues = bankFormRef?.current?.setValues;

    const _bankDetailRes = await bankDetail();
    if (!setValues) {
      return;
    }
    dispatch(onboardAch());
    const bankDetailRes =
      _bankDetailRes.data.entity?.[0] || _bankDetailRes.data.entity;
    setLoadingBank(false);
    setValues({
      ...formRef?.current?.values,
      bank_account: {
        custom: {
          ...bankDetailRes,
          // account_number: maskString(...bankDetailRes.account_number, "x")
        },
      },
    });
    if (!_setValues) {
      return;
    }
    _setValues({
      ...formRef?.current?.values,
      bank_account: {
        custom: {
          ...bankDetailRes,
          // account_number: maskString(...bankDetailRes.account_number, "x")
        },
      },
    });
  };
  const _hide = () => {
    setShowAgreementModal(false);
    setConsentDetail({
      consent_date: moment().format("YYYY-MM-DD"),
      type: ConsentType.GRAIL_PAY,
      sub_type: ConsentSubType.ACH,
      name: "",
    });
    setAllCheck({
      ACHAuthCheck: false,
      signedAuth: false,
      customerAuthCheck: false,
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      const { setValues } = formRef?.current;
      try {
        const onBoardAchRes = await getOnBoardAch();
        setEditable(true);
        const phone = onBoardAchRes.data.entity.phone.split("").includes("+")
          ? onBoardAchRes.data.entity.phone
          : "+1" + onBoardAchRes.data.entity.phone;
        setValues({
          bank_account: {
            custom: { ...initialValues.bank_account.custom },
          },
          ...onBoardAchRes.data.entity,
        });

        setLoadingBank(true);
        getBank();
        setEditable(true);
        // setLoadingBank(false);
      } catch (error) {
        setEditable(false);
        setLoadingBank(false);
        console.error(error);
      }
    };

    fetchData();
  }, []);

  async function updateBankDetail(values, setValues) {
    try {
      const res = await postBankDetail({ ...values.bank_account });
      notification.success({ message: "Bank details updated" });
      const updatedValues = {
        ...formRef?.current?.values,
        bank_account: { custom: { ...res.data.entity } },
      };
      setValues(updatedValues);
      closeModal();
      getBank();
      _consentTenant();
    } catch (err) {
      displayError(err);
    }
  }

  const displayError = (err) => {
    if (err.response && err.response.data && err.response.data.errors) {
      const errorData = err.response.data.errors;

      if (Array.isArray(errorData)) {
        errorData.forEach((err) => {
          notification.error({ message: err });
        });
      } else if (typeof errorData === "object") {
        Object.keys(errorData).forEach((key) => {
          const errorValue = errorData[key];

          if (Array.isArray(errorValue)) {
            errorValue.forEach((errorMessage) => {
              if (typeof errorMessage === "string") {
                notification.error({ message: errorMessage });
              }
            });
          } else if (typeof errorValue === "string") {
            notification.error({ message: errorValue });
          }
        });
      }
    }
    notification.error({ message: err.response?.data?.message });
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        innerRef={formRef}
        validationSchema={ON_BOARDING_CUSTOMER_SCHEMA}
        onSubmit={(_values, actions) => {
          const values = { ..._values };
          if (values.phone.startsWith("+1")) {
            values.phone = values.phone.substring(2);
          }
          delete values.address;
          delete values.business;
          setIsSubmitting(true);
          onBoardAch(values)
            .then((res) => {
              _consentTenant();
              setIsSubmitting(false);
              actions.resetForm();
              actions.setValues({
                bank_account: {
                  custom: { ...initialValues.bank_account.custom },
                },
                ...res.data.entity,
              });
              notification.success({ message: res.data.message });
              !!cb && cb();
              getBank();
              setEditable(true);
              onHide();
            })
            .catch((err) => {
              setIsSubmitting(false);
              displayError(err);
              console.dir(err);
            });
          actions.setSubmitting(false);
        }}
      >
        {({
          values,
          validateForm,
          errors,
          setFieldValue,
          setValues,
          setTouched,
          touched,
          handleSubmit,
          resetForm,
        }) => (
          <Form>
            <div>
              <div>
                {/* {console.log(
                  "test",
                  values,
                  "" + (values?.tin || values.business.tin)
                )} */}
                <div className="Ach-info-container">
                  {/* <Row className="mt-2" gutter={[20, 20]}>

              <Col span={8}>
                <label className="mx-3">Should Do KYB</label>
                <Switch
                  defaultChecked={values?.should_do_kyb}
                  disabled={true}
                  name="should_do_kyb"
                  size="medium"
                />
              </Col>
            </Row> */}
                  {/* Business Information */}
                  <h3 className="">Basic Information</h3>
                  <div
                    style={
                      editable ? { pointerEvents: "none", opacity: 0.5 } : null
                    }
                  >
                    <Row gutter={[20, 20]} className="Ach-info-container">
                      <Col sm={24} lg={8} md={8} className="h-32-px">
                        <label className="forms-labels">
                          Business Name
                        </label>
                        <Field
                          type="text"
                          name="business.name"
                          component={customInput}
                          className="customized-input"
                          placeholder="Business Name"
                          // label={
                          //   <span className="forms-labels">Business Name</span>
                          // }
                          value={values?.name || values?.business?.name || ""}
                          onChange={(e) =>
                            setFieldValue(e.target.name, e.target.value)
                          }
                        />
                        {/* <label>Business Name</label>
                      <Input
                        name="business.name"
                        value={values?.name || values.business.name}
                        onChange={(e) =>
                          setFieldValue(e.target.name, e.target.value)
                        }
                        placeholder="Business Name"
                      /> */}
                        {/* {touched?.business?.name && errors?.business?.name ? (
                        <small style={{ color: "red" }}>
                          {errors.business.name}
                        </small>
                      ) : null} */}
                      </Col>
                      <Col sm={24} lg={8} md={8} className="h-32-px">
                        <label className="forms-labels">
                          Business Tin
                        </label>
                        {editable ? (
                          <Input
                            className="customized-input"
                            name="business.name"
                            value={values?.tin}
                          />
                        ) : (
                          // <Field name="business.name" value={values?.tin} />
                          // <Input name="business.name" value={values?.tin} />
                          <>
                            {/* <label className="forms-labels">
                              Business Tin<span className="asterisk">*</span>
                            </label> */}
                            <MaskedInput
                              className="customized-input"
                              name="business.tin"
                              mask={"000000000"}
                              value={values?.tin || values?.business?.tin || ""}
                              onChange={(e) => {
                                setFieldValue(e.target.name, e.target.value);
                              }}
                              onBlur={(e) => {
                                const statement = e.target.name + ":true";
                                setTouched({
                                  ...convertStatementToObject(
                                    touched,
                                    statement
                                  ),
                                });
                              }}
                            />
                          </>
                        )}
                        {/* {touched?.business?.tin && errors?.business?.tin ? (
                        <small style={{ color: "red" }}>
                          {errors.business.tin}
                        </small>
                      ) : null} */}
                      </Col>
                      <Col sm={24} lg={8} md={8}>
                        <label className="forms-labels">
                          Phone<span className="asterisk">*</span>
                        </label>
                        <PhoneInput
                          className="custom-phone-input"
                          onlyCountries={["us"]}
                          country={"us"}
                          inputStyle={{ maxWidth: "33.33%" }}
                          masks={{ us: ".........." }}
                          name="phone"
                          placeholder="Phone"
                          value={values.phone}
                          onChange={(e) => {
                            setFieldValue("phone", `+${e}`);
                          }}
                        />
                        {/* {touched?.phone && errors?.phone ? (
                        <small style={{ color: "red" }}>{errors.phone}</small>
                      ) : null} */}
                      </Col>
                    </Row>
                    <Row gutter={[20, 20]} className="Ach-info-container">
                      <Col sm={24} lg={12} md={12}>
                        <label className="forms-labels">
                          Address Line 1
                        </label>
                        <Field
                          type="text"
                          component={customInput}
                          className="customized-input"
                          // label={
                          //   <span className="forms-labels">Address Line 1</span>
                          // }
                          name="address.line_1"
                          value={
                            values?.address?.street_address ||
                            values?.address?.line_1
                          }
                          placeholder="Address Line 1"
                          onChange={(e) =>
                            setFieldValue(e.target.name, e.target.value)
                          }
                        />
                        {/* <label> Address</label>
                      <Input.TextArea
                        name="address.line_1"
                        value={
                          values?.address?.street_address ||
                          values?.address?.line_1
                        }
                        placeholder="Address"
                        onChange={(e) =>
                          setFieldValue(e.target.name, e.target.value)
                        }
                      /> */}
                        {/* {touched?.address?.line_1 && errors?.address?.line_1 ? (
                        <small style={{ color: "red" }}>
                          {errors.address.line_1}
                        </small>
                      ) : null} */}
                      </Col>
                      <Col sm={24} lg={12} md={12} className="h-32-px">
                        <label className="forms-labels">
                          Address Line 2
                        </label>
                        <Field
                          type="text"
                          component={customInput}
                          className="customized-input"
                          // label={
                          //   <span className="forms-labels">Address Line 2</span>
                          // }
                          name="address.line_2"
                          value={
                            values?.address?.additional_address ||
                            values?.address?.line_2
                          }
                          placeholder="Address Line 2"
                          onChange={(e) =>
                            setFieldValue(e.target.name, e.target.value)
                          }
                        />
                        {/* <label> Other Address</label>
                      <Input.TextArea
                        name="address.line_2"
                        value={
                          values?.address?.additional_address ||
                          values?.address?.line_2
                        }
                        placeholder="Address"
                        onChange={(e) =>
                          setFieldValue(e.target.name, e.target.value)
                        }
                      /> */}
                        {/* {touched?.address?.line_2 && errors?.address?.line_2 ? (
                        <small style={{ color: "red" }}>
                          {errors.address.line_2}
                        </small>
                      ) : null} */}
                      </Col>
                    </Row>
                    <Row gutter={[20, 20]} className="Ach-info-container">
                      <Col sm={24} lg={8} md={8}>
                        {/* <Field
                        label="State*"
                        component={CustomSelect}
                        // options={USA_STATES}
                        name="address.state"
                        placeholder="State"
                        // showSearch
                        // className="border border-3 w-100 h-32-px"
                        value={values?.address?.state || null}
                        // size="middle"
                        onChange={(e, ee) => {
                          setFieldValue("address.state", e);
                           handleStateChange(ee.children);
                        }}
                        customFeedbackLabel=" "
                      /> */}
                        <label className="forms-labels">
                          State
                        </label>
                        <Select
                          name="address.state"
                          placeholder="State"
                          showSearch
                          className="selector-field"
                          value={values?.address?.state || null}
                          style={{ minHeight: "32px", marginTop: "6px" }}
                          size="middle"
                          onChange={(e, ee) => {
                            setFieldValue("address.state", e);
                            handleStateChange(ee.children);
                          }}
                        >
                          {USA_STATES.map((el) => (
                            <Select.Option value={el.value} key={el.value}>
                              {el.label}
                            </Select.Option>
                          ))}
                          <Select.Option value={"other"}>Other</Select.Option>
                        </Select>
                        {touched?.address?.state && errors?.address?.state ? (
                          <small style={{ color: "red" }}>
                            {errors.address.state}
                          </small>
                        ) : null}
                      </Col>
                      <Col sm={24} lg={8} md={8}>
                        {/* <Field
                        label="City*"
                        component={CustomSelect}
                        options={cities}
                        name="address.city"
                        placeholder="City"
                        // showSearch
                        // className="border border-3 w-100"
                        value={values?.address?.city || null}
                        // size="middle"
                        loading={IsLoading}
                        // style={{ minHeight: "32px" }}
                        onChange={(e) => {
                          setFieldValue("address.city", e);
                        }}
                        customFeedbackLabel=" "
                      /> */}
                        <div>
                          <label className="forms-labels">
                            City
                          </label>
                          <Select
                            name="address.city"
                            placeholder="City"
                            showSearch
                            className="selector-field"
                            value={values?.address?.city || null}
                            size="middle"
                            loading={IsLoading}
                            style={{ minHeight: "32px", marginTop: "6px" }}
                            onChange={(e) => {
                              setFieldValue("address.city", e);
                            }}
                          >
                            {cities.map((el) => (
                              <Select.Option value={el} key={el}>
                                {el}
                              </Select.Option>
                            ))}
                            <Select.Option value={"other"}>other</Select.Option>
                          </Select>
                        </div>
                        {touched?.address?.city && errors?.address?.city ? (
                          <small style={{ color: "red" }}>
                            {errors.address.city}
                          </small>
                        ) : null}
                      </Col>
                      <Col sm={24} lg={8} md={8}>
                        <label className="forms-labels">
                          Zip Code
                        </label>
                        <Field
                          type="text"
                          value={values.address?.zip}
                          name="address.zip"
                          component={customInput}
                          className="customized-input"
                          placeholder="Zip Code"
                          // label={<span className="forms-labels">Zip Code</span>}
                          onChange={(e) =>
                            setFieldValue(e.target.name, e.target.value)
                          }
                        />
                        {/* <label> Zip Code</label>
                      <Input
                        value={values.address.zip}
                        name="address.zip"
                        onChange={(e) =>
                          setFieldValue(e.target.name, e.target.value)
                        }
                        placeholder="Zip Code"
                      /> */}
                        {/* {touched?.address?.zip && errors?.address?.zip ? (
                        <small style={{ color: "red" }}>
                          {errors.address.zip}
                        </small>
                      ) : null} */}
                      </Col>
                    </Row>
                  </div>
                  {/* Bank Account Detail*/}
                  <h3 style={{ paddingTop: "24px" }}>
                    Bank Details
                    {/* {editable ? (
                    <div
                      class="position-absolute"
                      style={{ inset: 0, left: "auto", zIndex: 1 }}
                    >
                      <Button
                        onClick={() => setIsBankModalOpen((prev) => !prev)}
                      >
                        Edit
                      </Button>
                    </div>
                  ) : null} */}
                  </h3>
                  <Spin spinning={loadingBank}>
                    {!editable ? (
                      <div
                        style={
                          editable
                            ? { pointerEvents: "none", opacity: 0.5 }
                            : {}
                        }
                      >
                        {" "}
                        <BanKDetail
                          setFieldValue={setFieldValue}
                          setTouched={setTouched}
                          errors={errors}
                          values={values}
                          touched={touched}
                          isDisabled={isDisabled}
                          editable={editable}
                        />{" "}
                      </div>
                    ) : (
                      <Formik
                        innerRef={bankFormRef}
                        initialValues={{
                          bank_account: { ...initialValues.bank_account },
                        }}
                        validationSchema={EDIT_BANK_SCHEMA}
                        onSubmit={(values) => {
                          setLoadingBank(true);
                          updateBankDetail(values, formRef?.current?.setValues);
                          // notification.success({
                          //   message: "Bank details updated",
                          // });
                          setShowAgreementModal(false);
                          onHide();
                          setLoadingBank(false);
                        }}
                      >
                        {({
                          values,
                          setFieldValue,
                          setValues,
                          setTouched,
                          touched,
                          handleSubmit,
                          validateForm,
                          resetForm,
                          errors,
                        }) => (
                          <Form>
                            <BanKDetail
                              setFieldValue={setFieldValue}
                              errors={errors}
                              setTouched={setTouched}
                              values={values}
                              editable={editable}
                              isDisabled={isDisabled}
                              touched={touched}
                            />
                            <div
                              style={{
                                display: "flex",
                                gap: "20px",
                                justifyContent: "flex-end",
                                marginBottom: "16px",
                              }}
                            >
                              <CustomButton
                                label="Cancel"
                                onClick={onHide}
                                style={{
                                  width: "193px",
                                  height: "44px",
                                  borderRadius: "50px",
                                  background: "#CCC",
                                  color: "#333333",
                                }}
                              />
                              {!isDisabled && (
                                <Button
                                  // label="Save"
                                  //  disabled={editable}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    console.log(errors);
                                    validateForm().then((result) => {
                                      if (!Object.keys(result)?.length) {
                                        setShowAgreementModal(true);
                                      } else {
                                        handleSubmit();
                                      }
                                    });
                                  }}
                                  className="ach-save-button"
                                >
                                  Save
                                </Button>
                              )}
                              {/* <CustomButton
                                label="Save"
                                
                                // onClick={handleSubmit}
                                style={{
                                  width: "193px",
                                  height: "44px",
                                  background: "#EA1E51",
                                  color: "#fff",
                                }}
                              /> */}
                            </div>

                            {/* <div className="d-flex justify-content-end">
                            <Button className="ml-1 mr-1" onClick={closeModal}>
                              Cancel
                            </Button>{" "}
                            <Button
                              className="mr-1"
                              type="primary"
                              onClick={handleSubmit}
                            >
                              Save
                            </Button>
                          </div> */}
                          </Form>
                        )}
                      </Formik>
                    )}
                  </Spin>
                </div>
                <div>
                  <Row justify="end">
                    <Col>
                      <Spin spinning={isSubmitting}>
                        {!editable ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                gap: "20px",
                                justifyContent: "flex-end",
                                marginBottom: "16px",
                              }}
                            >
                              <CustomButton
                                label="Cancel"
                                onClick={onHide}
                                className="ach-cancel-button"
                              />
                              <Button
                                // label="Save"
                                //  disabled={editable}
                                onClick={(e) => {
                                  e.preventDefault();
                                  console.log(errors);
                                  validateForm().then((result) => {
                                    if (!Object.keys(result)?.length) {
                                      setShowAgreementModal(true);
                                    } else {
                                      handleSubmit();
                                    }
                                  });
                                }}
                                className="ach-save-button"
                              >
                                Save
                              </Button>
                            </div>
                          </>
                        ) : null}
                      </Spin>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {showAgreementModal ? (
        <div
          style={{
            zIndex: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            inset: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <TermsConditionModalContent
            setAllCheck={setAllCheck}
            allCheck={allCheck}
            submit={
              bankFormRef?.current?.handleSubmit ||
              formRef?.current?.handleSubmit
            }
            hide={_hide}
            consentDetail={consentDetail}
            setConsentDetail={setConsentDetail}
          />
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    props: state.saleData,
  };
};
export default connect(mapStateToProps)(AchPaymentForm);

export const BanKDetail = ({
  setFieldValue,
  setTouched,
  values,
  touched,
  errors,
  isDisabled,
  editable,
}) => {
  return (
    <Row className="achContainer" gutter={[20, 20]}>
      <Col span={24} sm={12} lg={8} md={8}>
        <Field
          component={customInput}
          className={isDisabled ? "field-disabled" : "customized-input"}
          label={
            <span className="forms-labels">
              Account Number<span className="asterisk">*</span>
            </span>
          }
          disabled={isDisabled}
          name="bank_account.custom.account_number"
          value={values?.bank_account?.custom?.account_number}
          onChange={(e) => setFieldValue(e.target.name, e.target.value)}
          placeholder="Account Number"
        />
      </Col>
      <Col span={24} sm={24} lg={8} md={8}>
        <Field
          label={
            <span className="forms-labels">
              Account Type<span className="asterisk">*</span>
            </span>
          }
          component={CustomSelect}
          className="selector-field"
          showOther
          disabled={isDisabled}
          options={AccountType}
          name="bank_account.custom.account_type"
          placeholder="Account Type"
          // value={values.bank_account?.custom?.account_type || null}
          onChange={(e) => {
            setFieldValue("bank_account.custom.account_type", e);
          }}
          customFeedbackLabel=" "
        />
      </Col>

      <Col span={24} sm={24} lg={8} md={8}>
        <label className="forms-labels">
          Routing Number<span className="asterisk">*</span>
        </label>
        <>
          {console.log(values?.bank_account?.custom?.routing_number)}
          <MaskedInput
            className={isDisabled ? "field-disabled" : "customized-input"}
            name="bank_account.custom.routing_number"
            disabled={isDisabled}
            mask={"000000000"}
            value={values?.bank_account?.custom?.routing_number}
            onChange={(e) => {
              setFieldValue(e.target.name, e.target.value);
            }}
            onBlur={(e) => {
              setTouched({ ...touched, [e.target.name]: true });
            }}
          />
        </>
      </Col>
      <Col span={24} sm={24} lg={8} md={8}>
        <Field
          component={customInput}
          className={isDisabled ? "field-disabled" : "customized-input"}
          label={
            <span className="forms-labels">
              Account Name<span className="asterisk">*</span>
            </span>
          }
          disabled={isDisabled}
          name="bank_account.custom.account_name"
          value={values.bank_account?.custom?.account_name}
          onChange={(e) => setFieldValue(e.target.name, e.target.value)}
          placeholder="Account Name"
        />
      </Col>
      {console.log("touched", touched, errors)}
    </Row>
  );
};

export const TermsConditionModalContent = ({
  setAllCheck,
  allCheck,
  hide,
  submit,
  consentDetail,
  setConsentDetail,
}) => {
  return (
    <div
      className="TCs-modal"
      style={{
        backgroundColor: " white",
        padding: "20px",
        borderRadius: "8px",
        margin: "auto 25%",
        fontSize: "16px",
        fontWeight: 300,
        lineHeight: "30px",
      }}
    >
      <div className="TCs-head">
        <h3 class="modal-label-name">Terms and Conditions</h3>
        <img
          onClick={hide}
          src={`${process.env.PUBLIC_URL}/media/icons/close.svg`}
          alt="close"
        ></img>
      </div>
      {/* <hr /> */}
      {/* <div className="d-flex">
        <Checkbox
          name="ACHAuthCheck"
          onChange={() => {
            setAllCheck((prev) => ({
              ...prev,
              ACHAuthCheck: !prev.ACHAuthCheck,
            }));
          }}
          checked={allCheck?.ACHAuthCheck}
        />
        <div className="px-2 mb-2">
          You can download a generic{" "}
          <a
            href={`${process.env.PUBLIC_URL}/media/ach/ACH-DEBIT-FORM.pdf`}
            target="blank"
          >
            ACH Authorization Form{" "}
          </a>{" "}
          here and please acknowledge you have signed authorization forms and
          Voided checks from your customer.{" "}
        </div>
      </div> */}
      <div className="content-container">
        <div className="TCs-body">
          <Checkbox
            class="checkbox-container"
            name="ACHAuthCheck"
            onChange={() => {
              setAllCheck((prev) => ({
                ...prev,
                ACHAuthCheck: !prev.ACHAuthCheck,
              }));
            }}
            checked={allCheck?.ACHAuthCheck}
          />
          <div style={{ marginLeft: "10px" }}>
            <p className="tc-para">
              By Proceeding further, you acknowledge that you have read the
              Terms and Conditions of the Business owner of this E-Commerce
              Site,{" "}
              <span>
                <a
                  className="linked-text"
                  // style={{ color: "#EA1E51 !important" }}
                  href={"https://khub.biz/terms"}
                  target="blank"
                >
                  Kommerce Hub Platform{" "}
                </a>
              </span>
              and affiliated Payment processors.
            </p>
            <p>
              If the payment is being made via ACH, you confirm you authorize
              the Business Owner to perform ACH debits or credits as per this
              authorization that you are agreeing to and also confirm that you
              have
              <span>
                <a
                  className="linked-text"
                  // style={{ color: "#EA1E51 !important" }}
                  href={`https://www.grailpay.com/ach-authorization`}
                  target="blank"
                >
                  {" "}
                  Read the terms of GrailPay
                </a>
              </span>{" "}
              who is the affiliated payment processor of Kommerce Hub.{" "}
            </p>
          </div>
        </div>
        <div className="signature-div">
          <div className="d-flex flex-column">
            <div className="date-cont">
              <span className="sign-title-text">Signed Date :</span>
              <span className="sign-date">{moment().format("YYYY-MM-DD")}</span>
            </div>
            <div className="d-flex">
              <span className="sign-title-text">Name:</span>
              <Input
                className="sign-input"
                placeholder="Type"
                value={consentDetail.name}
                onChange={(e) =>
                  e.target.value?.length < 35 &&
                  setConsentDetail((prev) => ({
                    ...prev,
                    [e.target.name]: e.target.value?.trimLeft(),
                  }))
                }
                style={{ height: "34px" }}
                name="name"
              />
            </div>
            <div>
              <span className="sign-title-text"> Signature:</span>
              <div
                className="border-bottom px-3"
                style={{
                  fontFamily: "'Rouge Script', cursive",
                  fontWeight: "700",
                  fontSize: "32px",
                }}
              >
                {" "}
                {consentDetail.name}
              </div>
            </div>
          </div>
        </div>
        {/* <hr /> */}
        <div className="TCs-btns">
          <a href={agreementLink} download>
            <Button
              className="TCs-auth-btn"
              href={`${process.env.PUBLIC_URL}/media/ach/ACH-DEBIT-FORM.pdf`}
              target="blank"
              variant="contained"
              color="primary"
            >
              Download ACH Authorization
            </Button>
          </a>
          <Button
            label="Submit"
            className="TCs-submit-btn"
            // type="primary"
            dsiabled={!allCheck.ACHAuthCheck || !allCheck.signedAuth}
            onClick={() => {
              if (allCheck.ACHAuthCheck && consentDetail.name) {
                submit();
              } else {
                !allCheck.ACHAuthCheck &&
                  notification.info({ message: "Check all the checkboxes" });
                !consentDetail.name &&
                  notification.info({ message: "Give signature" });
              }
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};
