import React from "react";

export default function PromotionsQTY({ selectedVariant, quantity, className }) {
  return (
    <>
    {selectedVariant?.selected_unit_price?.promotion?.min_order_qty ? (
      <p className={className ? className : "promotion-qty"}>
        MOQ Disc: Qty: {selectedVariant?.selected_unit_price?.promotion?.min_order_qty}
      </p>
    ) : (
      quantity && <p>Quantity</p>
    )}
  </>
  );
}
