import { FETCH_SETTINGS_SUCCESS, SET_SETTINGS } from "../actions/storeSettingsActions";

const initState = {
  ecom: {
    basic: {
      facebook: "",
      instagram: "",
      twitter: "",
      youtube: "",
      logo: "",
      favicon: "",
      currency_symbol: "$",
      currency_type: "ARS",
      channel_name: "Primary",
      channel_id: 1,
      tax: 0,
      tax_type: 1,
      delivery_charges: 0,
      currencyRate: 1,
      attributes: [],
      skip_eligibility_check: false,
      ecom_title: "Ecom Site",
      footer_description: "",
      copyrights_description: "",
    },
    theme: {
      facebook: "",
      instagram: "",
      twitter: "",
      youtube: "",
      logo: "",
      favicon: "",
      currency_symbol: "$",
      currency_type: "ARS",
      channel_name: "Primary",
      channel_id: 1,
      tax: 0,
      tax_type: 1,
      delivery_charges: 0,
      currencyRate: 1,
      attributes: [],
      skip_eligibility_check: false,
      ecom_title: "Ecom Site",
      footer_description: "",
      copyrights_description: "",
    },
  },
};

const settingsReducer = (state = initState, action) => {
  debugger
  switch (action.type) {
    case FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_SETTINGS:
      return {
        ...state,
        ecom: {
          ...state.ecom,
          basic: { ...action.payload },
          theme: { ...action.payload },
        },
      };
    default:
      return state;
  }
};

export default settingsReducer;
