import React from 'react';
import { NavLink, useLocation } from "react-router-dom";

const Navigation = ({className, settingData, themeSettingData, flatten}) => {
  const location = useLocation()

  const isPathActive = () => {
    return themeSettingData?.custom_page_header?.some(header => location.pathname === `${process.env.PUBLIC_URL}/${header.slug}`);
  };

  return (
    <div class={`header-navigation ${className}`}>
      <NavLink to={process.env.PUBLIC_URL + "/"} className="navigation-item" activeClassName={location.pathname == '/' ? "active" : ""} >Home</NavLink>
      <NavLink to={process.env.PUBLIC_URL + "/shop"} className="navigation-item" activeClassName="active" >Shop</NavLink>
      <NavLink to={process.env.PUBLIC_URL + "/quick-order"} className="navigation-item mobile-hide-header" activeClassName="active" >Quick Order</NavLink>
      
      {flatten && (
        themeSettingData?.custom_page_header?.map((custom_header, index) => (
          <NavLink key={index} to={process.env.PUBLIC_URL + `/${custom_header.slug}`} className="navigation-item"  activeClassName="active">{custom_header.label}</NavLink>
        ))
      )}
      {themeSettingData?.custom_page_header?.length > 0 && !flatten && (
        <div class="tw-relative navigation-dropdown">
          <button 
            type="button"
            aria-expanded="false"
            onClick={(e) => e.preventDefault()}
            className={`navigation-item ${isPathActive() ? 'active' : ''} `}
          >
            More
            <svg class="tw-h-5 tw-w-5 tw-flex-none" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
            </svg>
          </button>
          <div className='dropdown-list'>
            {themeSettingData?.custom_page_header?.map(custom_header => (
              <NavLink to={process.env.PUBLIC_URL + `/${custom_header.slug}`} activeClassName="active">{custom_header.label}</NavLink>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Navigation;
