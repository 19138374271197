import React from "react";
import Countdown from "react-countdown";
import { getTimeByTimeZonePromotion } from "../../pages/constant/utils";
import moment from "moment";
import "./style.css";
import { counterDuration } from "../../pages/constant/Constants";

const TimerSegment = ({ value, label, subClassName, className, templateThree }) => (
  <div style={{ display: "flex", flexDirection: "column" }}>
    <div className={subClassName}>
      {templateThree == true ? (
        <span className={className}>{value}</span>
      ) : (
        value.toString().split('').map((digit, index) => (
          <span key={index} className={className}>{digit}</span>
        ))
      )}
    </div>
    <p style={{ alignSelf: "center" }}>{label}</p>
  </div>
);

const TimerTemplateOne = ({ days, hours, minutes, seconds, heading }) => (
  <div className="main-promotion-timer-template-one">
    {<p className="promo-timer-template-one">Hurry!! Offer ends in:</p>}
    {days > 0 && <span className="promo-timer-template-one">{days} Days </span>}
    {hours > 0 && <span className="promo-timer-template-one">{hours < 10 ? `0${hours}` : hours} Hours </span>}
    {minutes > 0 && <span className="promo-timer-template-one">{minutes < 10 ? `0${minutes}` : minutes} Mins </span>}
    {seconds > 0 && <span className="promo-timer-template-one">{seconds < 10 ? `0${seconds}` : seconds} Sec</span>}
  </div>
);

const TimerTemplateTwo = ({ days, hours, minutes, seconds, heading }) => (
  <div>
    {<p className="promo-heading">Promotion ends in:</p>}
    {days > 0 && <span className="promo-timer">{days} Days </span>}
    {hours > 0 && <span className="promo-timer">{hours < 10 ? `0${hours}` : hours} Hours </span>}
    {minutes > 0 && <span className="promo-timer">{minutes < 10 ? `0${minutes}` : minutes} Mins </span>}
    {seconds > 0 && <span className="promo-timer">{seconds < 10 ? `0${seconds}` : seconds} Sec</span>}
  </div>
);

const TimerTemplateThree = ({ days, hours, minutes, seconds }) => (
  <div className="main-promotion-timer-template-three">
    {days > 0 && (
      <>
        <TimerSegment templateThree={true} value={days} label="Days" subClassName="sub-promotion-timer-template-three"
          className="promo-timer-template-three" />
        <div className="partition-template-three">
          <div className="circle-template-three"></div>
          <div className="circle-template-three"></div>
        </div>
      </>
    )}
    {hours > 0 && (
      <>
        <TimerSegment templateThree={true} value={hours} label="Hours" subClassName="sub-promotion-timer-template-three"
          className="promo-timer-template-three" />
        <div className="partition-template-three">
          <div className="circle-template-three"></div>
          <div className="circle-template-three"></div>
        </div>
      </>
    )}
    {minutes > 0 && (
      <>
        <TimerSegment templateThree={true} value={minutes} label="Minutes" subClassName="sub-promotion-timer-template-three"
          className="promo-timer-template-three" />
        <div className="partition-template-three">
          <div className="circle-template-three"></div>
          <div className="circle-template-three"></div>
        </div>
      </>
    )}
    {seconds > 0 && <TimerSegment templateThree={true} value={seconds} label="Seconds" subClassName="sub-promotion-timer-template-three"
      className="promo-timer-template-three" />}
  </div>
);

const TimerTemplateFour = ({ days, hours, minutes, seconds }) => (
  <div className="main-promotion-timer-template-four">
    {days > 0 && <div className="sub-promotion-timer-template-four">
        <TimerSegment value={days} className="promo-timer-template-four" />
        <p className="promo-timer-template-four-text">Days</p>
      </div>}
    {hours > 0 && <div className="sub-promotion-timer-template-four">
        <TimerSegment value={hours} className="promo-timer-template-four" />
        <p className="promo-timer-template-four-text">Hours</p>
      </div>}
    {minutes > 0 && <div className="sub-promotion-timer-template-four">
        <TimerSegment value={minutes} className="promo-timer-template-four" />
        <p className="promo-timer-template-four-text">Min</p>
      </div>}
    {seconds > 0 && <div className="sub-promotion-timer-template-four">
        <TimerSegment value={seconds} className="promo-timer-template-four" />
        <p className="promo-timer-template-four-text">Sec</p>
      </div>}
  </div>
);

const TimerTemplateFive = ({ days, hours, minutes, seconds }) => (
  <div className="main-promotion-timer-template-five">
    {days > 0 &&
      <>
        <TimerSegment value={days} label="Days" subClassName="sub-promotion-timer-template-five"
          className="promo-timer-template-five" />
        <div className="partition">
          <div className="circle"></div>
          <div className="circle"></div>
        </div>
      </>}
    {hours > 0 &&
      <>
        <TimerSegment value={hours} label="Hours" subClassName="sub-promotion-timer-template-five"
          className="promo-timer-template-five" />
        <div className="partition">
          <div className="circle"></div>
          <div className="circle"></div>
        </div></>}
    {minutes > 0 &&
      <>
        <TimerSegment value={minutes} label="Min" subClassName="sub-promotion-timer-template-five"
          className="promo-timer-template-five" />
        <div className="partition">
          <div className="circle"></div>
          <div className="circle"></div>
        </div></>
    }
    {seconds > 0 && <TimerSegment value={seconds} label="Sec" subClassName="sub-promotion-timer-template-five"
      className="promo-timer-template-five" />}
  </div>
);

export default function PromotionsTimer({ product, heading, showNoPromo, settings }) {
  const dateTimeFormat = "YYYY-MM-DDTHH:mm:ss";
  let targetDate = null;
  if (product?.selected_unit_price?.promotion?.end_datetime) {
    targetDate = moment(getTimeByTimeZonePromotion(settings?.timezone, product.selected_unit_price.promotion.end_datetime)).format(dateTimeFormat);
  } else if (product?.variant_product?.length) {
    targetDate = getTimeByTimeZonePromotion(
      settings?.timezone,
      product.variant_product.find(
        variant => variant?.selected_unit_price?.promotion?.end_datetime
      )?.selected_unit_price?.promotion?.end_datetime
    );
  }

  const returnHoursFromDays = (hours, days = 0) => {
    if (isNaN(hours) || isNaN(days)) return 0;
    return hours + (days * 24);
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed || !product.selected_unit_price?.promotion?.has_counter ||
      (product?.selected_unit_price?.promotion?.counter_duration == counterDuration.LAST_24_HR && returnHoursFromDays(hours, days) >= 24) ||
      (product?.selected_unit_price?.promotion?.counter_duration == counterDuration.LAST_12_HR && returnHoursFromDays(hours, days) >= 12)) {
      return null;
    } else {
      switch (product.selected_unit_price?.promotion?.counter_template) {
        case 1:
          return <TimerTemplateOne days={days} hours={hours} minutes={minutes} seconds={seconds} />;
        case 2:
          return <TimerTemplateTwo days={days} hours={hours} minutes={minutes} seconds={seconds} />;
        case 3:
          return <TimerTemplateThree days={days} hours={hours} minutes={minutes} seconds={seconds} />;
        case 4:
          return <TimerTemplateFour days={days} hours={hours} minutes={minutes} seconds={seconds} />;
        case 5:
          return <TimerTemplateFive days={days} hours={hours} minutes={minutes} seconds={seconds} />;
        default:
          return null;
      }
    }
  };

  if (showNoPromo && !targetDate) {
    return <p style={{ color: "red" }}>Not on Promotion</p>;
  }

  return (
    <>
      <Countdown
        date={targetDate}
        renderer={renderer}
      />
    </>
  );
}