import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import MetaTags from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { connect } from "react-redux";
import { getDiscountPrice } from "../../helpers/product";
import { Units, searchFilter, taxType, defaultLogo, defaultImageUrl, saleBadgeFour } from "../constant/Constants";
import {
  addToCart,
  decreaseQuantity,
  increaseQuantity,
  deleteFromCart,
  deleteAllFromCart,
  deleteAllFromCartViaApi,
} from "../../redux/actions/cartActions";
import { notification, Popover, Switch, Image, Input } from "antd";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { formatCurrency, getPriceRelatedToCustomer } from "../constant/utils";
import { fetchProductsForQuick } from "../../redux/actions/productActions";
import Button from "../../components/utils/Button";
import Upcoming from "./../../assets/images/Upcoming.png";
import emptyCartImage from "./../../assets/images/ic-cart.svg"
import Promotions from "../../components/common/Promotions";
import PromotionsPrice from "../../components/common/PromotionPrice";
import PromotionsQTY from "../../components/common/PromotionQTY";
import PromotionsTimer from "../../components/common/PromotionTimer";

const Cart = ({
  cartItems,
  settings,
  themeSettings,
  userDetail,
  decreaseQuantity,
  increaseQuantity,
  userData,
  deleteFromCart,
  adminSettings,
  deleteAllFromCartViaApi,
}) => {
  const {
    ecom_title,
    currencyRate,
    price_display_for_unsecure_user,
    price_display_for_secure_user,
  } = settings || {};
  const {
    currency_symbol,
  } = adminSettings.basic
  const { tax, tax_type, } = adminSettings.pos || {}
  const history = useHistory();
  const { addToast } = useToasts();
  let cartTotalPrice = 0;

  const subtotal = () => {
    let cartTotalPrice = 0;
    cartItems.map((product, key) => {
      
      const discountedPrice = getDiscountPrice(product.selected_unit_price.price, product?.discount || 0);
      const finalProductPrice = +(
        product?.selected_unit_price?.promotion?.min_order_qty <= product.quantity  ? (
          product?.selected_unit_price?.promotion?.price
        ) : (
          product?.selected_unit_price?.price
        )
      )?.toFixed(2);
      // getPriceRelatedToCustomer(userDetail, product) * currencyRate||1

      const finalDiscountedPrice = (discountedPrice * currencyRate || 1)?.toFixed(2);

      discountedPrice != null
        ? (cartTotalPrice += finalDiscountedPrice * product.quantity)
        : (cartTotalPrice += finalProductPrice * product.quantity);
    });
    return cartTotalPrice;
  };

  const cartGrandTotal = (productTotal) => {
    cartTotalPrice = 0;
    cartItems.map((product, key) => {
      const discountedPrice = getDiscountPrice(product.price, product.discount);
      const finalProductPrice = +(
        product?.selected_unit_price?.price
      )?.toFixed(2);

      const finalDiscountedPrice = (discountedPrice * currencyRate || 1).toFixed(2);

      discountedPrice != null
        ? (cartTotalPrice += finalDiscountedPrice * product.quantity)
        : (cartTotalPrice += finalProductPrice * product.quantity);
    });

    return cartTotalPrice;
  };

  return (
    <Fragment>
      <MetaTags>
        <title>{ecom_title} | Cart</title>
        <meta
          name="description"
          content={`Cart page of ${ecom_title} eCommerce template.`}
        />
      </MetaTags>

      {/* <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + history.location.pathname}>
        Cart
      </BreadcrumbsItem> */}

      <LayoutOne headerTop="visible">
        {/* <Breadcrumb /> */}
        <div className="shopping-quick-order-main-area pt-15 pb-100">
          {cartItems.length !== 0 ? (
            <div className="shopping-heading-row">
              <div className="shopping-cart-heading">Shopping Cart</div>
            </div>
          ) : null}
          <div className="cart-main-area">
            <div className="shopping-row">
              <div className="product-section">
                {cartItems.length === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        height: "auto",
                        // gap: "31px",
                        marginTop: "32px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="empty-cart"
                        style={{
                          height: "350px",
                          width: "350px",
                        }}
                      >
                        <img
                          style={{
                            maxWidth: "100%",
                          }}
                          src={emptyCartImage}
                        />
                      </div>
                      <span className="cart-para">Cart is empty!</span>
                      <span className="cart-text">
                        Look like you have no items in your shopping cart.
                      </span>
                      <a
                        href={`${process.env.PUBLIC_URL}/shop`}
                        style={{
                          textDecoration: "none",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          className="goto-shopBtn"
                          label="Go to Shop"
                          style={{
                            width: "213px",
                            height: "52px",
                            padding: "10px",
                            borderRadius: "32px",
                            marginTop: "24px",
                            marginBottom: "16px",
                            background: "var(--style-primary-color)",
                            color: "#fff",
                          }}
                        />
                      </a>
                      {/* <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/img/go-to-button.png"
                        }
                        className="cursor-pointer"
                        onClick={() => {
                          history.push("/shop");
                        }}
                      /> */}
                    </div>
                  </div>
                ) : (
                  <div className="product-table" style={{ width: "1169px" }}>
                    <table width="100%" className="pro-table-haed">
                      <thead>
                        <tr
                          style={{
                            borderBottom: "1px solid #B3B3B3",
                          }}
                        >
                          <th className="image-column cart-table-label">Image</th>
                          <th className="product-name-column cart-table-label">Product name</th>
                          {/* <th className="upc">UPC</th> */}
                          <th className="unit cart-table-label">Unit</th>
                          <th className="qty cart-table-label">Quantity</th>
                          {((!userDetail?.user && settings.price_display_for_unsecure_user) ||
                            (userDetail?.user && settings.price_display_for_secure_user)) && (
                              userData && (
                                <th className="price cart-table-label">Price</th>
                              )
                            )}
                          <th style={{ width: "30px" }}></th>
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {cartItems &&
                          cartItems.length >= 1 &&
                          cartItems?.filter((item) => item.quantity > 0)?.map((product, key) => {
                            return (
                              <tr
                                className="grid-container reposition-on-mobile"
                                style={{
                                  borderBottom: "1px solid #B3B3B3",
                                }}
                              >
                                <td>
                                  <div className="image-column grid-item">
                                    <Popover
                                      zIndex={2000}
                                      title=""
                                      trigger="hover"
                                    >
                                      <Image
                                        src={
                                          (product?.images?.[0] ?? product?.product?.images?.[0]) === defaultImageUrl
                                            ? (themeSettings.logo === defaultLogo ? Upcoming : themeSettings.logo)
                                            : ((product?.images?.[0] ?? product?.product?.images?.[0]) ? process.env.PUBLIC_URL + (product?.images?.[0] ?? product?.product?.images?.[0]) : "")
                                        }
                                        width={"70px"}
                                        height={"69px"}

                                        style={{
                                          width: "100%", height: "69px", objectFit: "contain", verticalAlign: "middle",
                                          objectFit: product?.product?.images?.[0] === defaultImageUrl ? "initial" : "contain"
                                        }}
                                      />
                                    </Popover>
                                  </div>
                                </td>
                                <td className="pro-name-td">
                                  <div>
                                  <div style={{display:"flex"}}>
                                <div className="pro-name">
                                  {product?.ecom_name || product?.name}
                                </div>
                                  <Promotions product={product} classMain={"promotion-quick"} classText={product?.selected_unit_price?.promotion?.sale_badge_template === saleBadgeFour ? "promotion-text-quick-four" : "promotion-text-quick"}/>
                                  </div>
                                    {((!userDetail?.user && settings.stock_display_for_unsecure_user) ||
                                      (userDetail?.user && settings.stock_display_for_secure_user)) && (
                                        <div
                                          className="out-of-stock"
                                          style={{
                                            display: "flex",
                                            width: "102px",
                                            height: "16px",
                                            padding: "7px 10px",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: "7px",
                                            borderRadius: "24px",
                                            background: product?.back_order_ecom ? "#FDFAEB" : product.saleable_qty <= 0 ? "#FFE7E5" : "#EFEDF8",
                                            marginBottom: "10px",
                                          }}
                                        >
                                          <span
                                            style={{
                                              display: "flex", width: "80px",
                                              height: "16px", flexDirection: "column",
                                              justifyContent: "center",
                                              color: product?.back_order_ecom ? "#EFD138" : product?.saleable_qty <= 0 ? "#FB1100" : "#5F4BB6",
                                              lineHeight: "18px",
                                              textAlign: "center", fontFamily: "Montserrat",
                                              fontSize: "12px", fontStyle: "normal", fontWeight: "500",
                                            }}
                                          >
                                            {product?.back_order_ecom ? "Low Stock" : product?.saleable_qty <= 0 ? "Out of Stock" : `Qty: ${product.saleable_qty || 0}`}
                                          </span>
                                        </div>
                                      )}
                                  </div>
                                </td>
                                {/* <td className="upc">{product.upc}</td> */}
                                <td className="cart-unit">
                                  <div
                                    className="pro-unit"
                                    style={{
                                      display: "flex",
                                      width:"100px",
                                      height: "32px",
                                      padding: "10px 16px",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      alignSelf: "stretch",
                                      borderRadius: "32px",
                                      background: "#F7F7F7",
                                    }}
                                  >
                                    {
                                      Units[product.selected_unit_price.unit]
                                        ?.name
                                    }
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/media/icons/arrows.svg"
                                      }
                                    />
                                  </div>
                                </td>
                                <td
                                  className="item"
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "127px",
                                      height: "32px",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      borderRadius: "32px",
                                      border: "1px solid #F2F2F2",
                                      background: "#F7F7F7",
                                    }}
                                  >
                                    <img
                                      onClick={() => {
                                        if (product.quantity > 0) {
                                          const filteredCartItems = cartItems?.filter((item) => item.quantity > 0);
                                          decreaseQuantity(filteredCartItems, product, addToast);
                                        }
                                      }}
                                      disabled={product.quantity === 0}
                                      className="cursor-pointer"
                                      src={
                                        `${process.env.PUBLIC_URL}` +
                                        "/media/icons/minus-icon.svg"
                                      }
                                    />
                                    <input
                                      type="number"
                                      // className="cursor-pointer"
                                      value={product.quantity > 0 ? product.quantity : 0}
                                      readOnly
                                      onChange={() => { }}
                                      style={{
                                        background: "#F7F7F7",
                                        border: "none",
                                        marginLeft: "15px",
                                      }}
                                    />
                                    <img
                                      onClick={() => {
                                        if (
                                          (product.back_order_ecom && ((product?.ps_allowed_qty &&
                                            (product.quantity || product.orderQty + 1) <
                                            product.ps_allowed_qty) || (!product?.ps_allowed_qty))) ||
                                          (!product.back_order_ecom &&
                                            (product.orderQty || product.quantity + 1) <
                                            product.saleable_qty)
                                        ) {
                                          const filteredCartItems = cartItems?.filter((item) => item.quantity > 0);
                                          increaseQuantity(filteredCartItems, product, addToast);
                                        } else {
                                          const errorMessage = `Sorry,the Max allowed quantity per sale is ‘MAx Qty’.`;
                                          addToast(errorMessage, {
                                            appearance: "error",
                                            autoDismiss: true,
                                          });
                                        }
                                      }}
                                      disabled={
                                        product !== undefined &&
                                        product.saleable_qty &&
                                        product.quantity >= product.saleable_qty
                                      }
                                      className="cursor-pointer"
                                      src={
                                        `${process.env.PUBLIC_URL}` +
                                        "/media/icons/plus-icon.svg"
                                      }
                                    />
                                  </div>
                                  <PromotionsQTY selectedVariant={product} quantity={false}/>
                                </td>
                                {((!userDetail?.user && settings.price_display_for_unsecure_user) ||
                                  (userDetail?.user && settings.price_display_for_secure_user)) && (
                                    userData && (
                                      <td className="price reposition-on-mobile" style={{display:"grid", width:"100%"}}>
                                        <PromotionsPrice product={product} currency={currency_symbol} regularprice={true}/>
                                      </td>
                                    )
                                  )}
                                <td
                                  className="del-item"
                                  style={{ textAlign: "center" }}
                                >
                                  <svg
                                    className="cursor-pointer"
                                    onClick={() =>
                                      deleteFromCart(
                                        cartItems,
                                        product,

                                        addToast
                                      )
                                    }
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                  >
                                    <path
                                      d="M12.6364 3.90909V3.18182C12.6364 1.97683 11.6595 1 10.4545 1H7.54545C6.34047 1 5.36364 1.97683 5.36364 3.18182V3.90909M1 3.90909H17M11.1818 8.27273V11.9091M6.81818 8.27273V11.9091M2.45455 3.90909H15.5455L15.0636 13.5452C14.9669 15.4805 13.3695 17 11.4318 17H6.56818C4.63045 17 3.03312 15.4805 2.93635 13.5452L2.45455 3.90909Z"
                                      stroke="#EA1E51"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                    />
                                  </svg>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              <div className="quick-cart">
                <div
                  className="cart-head"
                  style={{
                    color: "#1A1A1A",
                    fontFamily: "Montserrat",
                    fontSize: "30px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "38px",
                    marginTop: "24px",
                    marginLeft: "20px",
                    marginBottom: "24px",
                  }}
                >
                  Cart
                </div>

                {/* Cart */}
                <div className="cart-added-product" style={{ height: "auto" }}>
                  {/* <div>Coupon Code</div>
                  <div
                  className="cart-lineItems"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      className="quick-order-scan-qty-class"
                      style={{ marginLeft: "0px" }}
                    >
                      <input className="coupon-code" placeholder="Type"
                      //  style={{ width: "310px" }}
                        />
                    </div>
                    <div>
                      <button
                      className="apply-coupon"
                        type="button"
                        style={{
                          display: "flex",
                          // width: "190px",
                          height: "50px",
                          padding: "10px",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                          borderRadius: "32px",
                          border: "none",
                          background: "#CFC9E9",
                          color: "#FFF",
                          textAlign: "center",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          lineHeight: "20px",
                        }}
                      >
                        Apply Coupon
                      </button>
                    </div>
                  </div> */}

                  <div
                    className="cart-lineItems"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "40px",
                    }}
                  >
                    <div
                      className="cart-labels"
                    >
                      Subtotal
                    </div>
                    <div
                      className="cart-values"
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        fontFamily: "Montserrat",
                        fontSize: "24px",
                        fontWeight: "500",
                        lineHeight: "32px",
                        letterSpacing: "0em",
                        textAlign: "right",
                      }}
                    >
                      {currency_symbol +
                        formatCurrency(subtotal()).toLocaleString()}
                    </div>
                  </div>
                  {/* <div
                  className="cart-lineItems add-style"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "40px",
                    }}
                  >
                    <div
                    className="cart-labels"
                      style={{
                       
                        fontFamily: "Montserrat",
                        fontSize: "18px",
                        fontWeight: "500",
                        lineHeight: "28px",
                        letterSpacing: "0em",
                        textAlign: "left",
                      }}
                    >
                      You Saved
                    </div>
                    <div
                    className="cart-values"
                      style={{
                        display:"flex",
                        alignItems:"flex-start",
                        fontFamily: "Montserrat",
                        fontSize: "24px",
                        fontWeight: "500",
                        lineHeight: "32px",
                        letterSpacing: "0em",
                        textAlign: "right",
                        color: "#FB1100",
                      }}
                    >
                      $0
                    </div>
                  </div> */}
                  {/* <div
                  className="cart-lineItems"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "40px",
                    }}
                  >
                    <div
                    className="cart-labels"
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "18px",
                        fontWeight: "500",
                        lineHeight: "28px",
                        letterSpacing: "0em",
                        textAlign: "left",
                      }}
                    >
                      Delivery Charges
                    </div>
                    <div
                    className="cart-values"
                      style={{
                        display:"flex",
                        alignItems:"flex-start",
                        fontFamily: "Montserrat",
                        fontSize: "24px",
                        fontWeight: "500",
                        lineHeight: "32px",
                        letterSpacing: "0em",
                        textAlign: "right",
                      }}
                    >
                      {currency_symbol +
                        formatCurrency(
                          settings.delivery_charges
                        ).toLocaleString()}
                    </div>
                  </div> */}
                  {/* <div
                    className="cart-lineItems add-style"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "40px",
                    }}
                  >
                    <div
                      className="cart-labels"
                    >
                      Estimated Tax
                    </div>
                    <div
                      className="cart-values"
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        fontFamily: "Montserrat",
                        fontSize: "24px",
                        fontWeight: "500",
                        lineHeight: "32px",
                        letterSpacing: "0em",
                        textAlign: "right",
                      }}
                    >
                      {tax_type === 2 &&
                        currency_symbol + tax}

                      {tax_type === 1
                        ? formatCurrency(tax).toLocaleString() +
                        taxType[1]
                        : null}
                    </div>
                  </div> */}
                  {/* <div
                    className="cart-lineItems-pretax"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "40px",
                      borderTop: "1px solid #E6E6E6",
                      borderBottom: "1px solid #E6E6E6",
                      height: "50px",
                    }}
                  >
                    <div
                      className="cart-labels"
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "18px",
                        fontWeight: "500",
                        lineHeight: "28px",
                        letterSpacing: "0em",
                        textAlign: "left",
                      }}
                    >
                      Pre-Tax Total
                    </div>
                    <div
                      className="cart-values"
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "24px",
                        fontWeight: "500",
                        lineHeight: "32px",
                        letterSpacing: "0em",
                        textAlign: "right",
                      }}
                    >
                      {currency_symbol +
                        formatCurrency(
                          cartGrandTotal(cartTotalPrice)
                        ).toLocaleString()}
                    </div>
                  </div> */}
                </div>

                {/* Cart ends */}

                <div className="checkout-btn-container">
                  <button
                    className="checkout-button"
                    onClick={() => {
                      history.push(`/checkout`);
                    }}
                    style={{
                      display: "flex",
                      width: "95%",
                      padding: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",

                      borderRadius: "32px",
                      background: "var(--style-primary-color)",
                      color: "#FFF",
                      textAlign: "center",
                      fontFamily: "Montserrat",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "28px",
                      border: "none",
                      margin: "24px 0px 24px 12px",
                    }}
                  >
                    Proceed To Checkout
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="container">
            {cartItems && cartItems.length >= 1 ? (
              <Fragment>
                <h3 className="cart-page-title">Your cart items</h3>
                <div className="row">
                  <div className="col-12">
                    <div className="table-content table-responsive cart-table-content">
                      <table>
                        <thead>
                          <tr>
                            <th>Image</th>
                            <th>Product Name</th>
                            {(userData
                              ? price_display_for_secure_user
                              : price_display_for_unsecure_user) && (
                              <th>Unit Price</th>
                            )}
                            <th>Qty</th>
                            {(userData
                              ? price_display_for_secure_user
                              : price_display_for_unsecure_user) && (
                              <th>Subtotal</th>
                            )}
                            <th>action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cartItems.map((cartItem, key) => {
                            const discountedPrice = getDiscountPrice(
                              cartItem.price,
                              cartItem.discount
                            );
                            const finalProductPrice = +(
                              cartItem?.unit_price ||
                              getPriceRelatedToCustomer(userDetail, cartItem) *
                                currencyRate
                            ).toFixed(2);

                            const finalDiscountedPrice = (
                              discountedPrice * currencyRate
                            ).toFixed(2);

                            discountedPrice != null
                              ? (cartTotalPrice +=
                                  finalDiscountedPrice * cartItem.quantity)
                              : (cartTotalPrice +=
                                  finalProductPrice * cartItem.quantity);
                            return (
                              <tr key={key}>
                                <td className="product-thumbnail">
                                  <Link
                                    to={
                                      process.env.PUBLIC_URL +
                                      "/product/" +
                                      cartItem.id
                                    }
                                  >
                                    <img
                                      className="img-fluid"
                                      src={
                                        process.env.PUBLIC_URL +
                                        cartItem.images[0]
                                      }
                                      alt=""
                                    />
                                  </Link>
                                </td>
                                <td>
                                  <Link
                                    to={
                                      process.env.PUBLIC_URL +
                                      "/product/" +
                                      cartItem.id
                                    }
                                  >
                                    {cartItem.name}
                                  </Link>
                                  {cartItem.selectedProductColor &&
                                    cartItem.selectedProductSize && (
                                      <div className="cart-item-variation">
                                        <span>
                                          Color: {cartItem.selectedProductColor}
                                        </span>
                                        <span>
                                          Size: {cartItem.selectedProductSize}
                                        </span>
                                      </div>
                                    )}
                                </td>
                                {(userData
                                  ? price_display_for_secure_user
                                  : price_display_for_unsecure_user) && (
                                  <td className="product-price-cart">
                                    {discountedPrice !== null ? (
                                      <Fragment>
                                        <span className="amount old">
                                          {currency_symbol + finalProductPrice}
                                        </span>
                                        <span className="amount">
                                          {currency_symbol +
                                            finalDiscountedPrice}
                                        </span>
                                      </Fragment>
                                    ) : (
                                      <span className="amount">
                                        {currency_symbol + finalProductPrice}
                                      </span>
                                    )}
                                  </td>
                                )}
                                <td className="product-quantity">
                                  <div className="cart-plus-minus">
                                    <button
                                      className="dec qtybutton"
                                      onClick={() =>
                                        decreaseQuantity(
                                          cartItems,
                                          cartItem,
                                           
                                          addToast
                                        )
                                      }
                                    >
                                      -
                                    </button>
                                    <input
                                      className="cart-plus-minus-box"
                                      type="text"
                                      value={cartItem.quantity}
                                      readOnly
                                    />
                                    <button
                                      className="inc qtybutton"
                                      onClick={() =>
                                        increaseQuantity(
                                          cartItems,
                                          cartItem,
                                           
                                          addToast
                                        )
                                      }
                                      disabled={
                                        cartItem !== undefined &&
                                        cartItem.saleable_qty &&
                                        cartItem.quantity >=
                                          cartItem.saleable_qty
                                      }
                                    >
                                      +
                                    </button>
                                  </div>
                                </td>
                                {(userData
                                  ? price_display_for_secure_user
                                  : price_display_for_unsecure_user) && (
                                  <td className="product-subtotal">
                                    {discountedPrice !== null
                                      ? currency_symbol +
                                        (
                                          finalDiscountedPrice *
                                          cartItem.quantity
                                        ).toFixed(2)
                                      : currency_symbol +
                                        (
                                          finalProductPrice * cartItem.quantity
                                        ).toFixed(2)}
                                  </td>
                                )}

                                <td className="product-remove">
                                  <button
                                    onClick={() =>
                                      deleteFromCart(
                                        cartItems,
                                        cartItem,
                                         
                                        addToast
                                      )
                                    }
                                  >
                                    <i className="fa fa-times"></i>
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* Wrting this line of code just to maintain the UI */}
            {/* <div
              className={`${
                (!userData && price_display_for_unsecure_user) ||
                (userData && price_display_for_secure_user)
                  ? `col-lg-12`
                  : `col-lg-10`
              }`}
            >
              <div className="cart-shiping-update-wrapper">
                <div className="cart-shiping-update">
                  <Link to={process.env.PUBLIC_URL + "/shop"}>
                    Continue Shopping
                  </Link>
                </div>
                <div className="cart-clear">
                  <button
                    onClick={() =>
                      deleteAllFromCartViaApi(addToast,   )
                    }
                  >
                    Clear Shopping Cart
                  </button>
                </div>
              </div>
            </div> */}
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-5"></div>
            <div
              className={`${(!userData && price_display_for_unsecure_user) ||
                (userData && price_display_for_secure_user)
                ? `col-lg-4`
                : `col-lg-2`
                }`}
            ></div>
            {/* Wrting this line of code just to maintain the UI */}
            {/* <div
              className={`${
                (!userData && price_display_for_unsecure_user) ||
                (userData && price_display_for_secure_user)
                  ? `col-lg-4`
                  : `col-lg-2`
              }`}
            ></div>
            <div className="col-lg-4 col-md-12">
              <div className="grand-totall">
                {(!userData && price_display_for_unsecure_user) ||
                  (userData && price_display_for_secure_user && (
                    <>
                      <div className="title-wrap">
                        <h4 className="cart-bottom-title section-bg-gary-cart">
                          Cart Total
                        </h4>
                      </div>
                      <h5>
                        Sub Total{" "}
                        <span>
                          {currency_symbol + formatCurrency(cartTotalPrice)}
                        </span>
                      </h5>
                    </>
                  ))}
                <Link to={process.env.PUBLIC_URL + "/checkout"}>
                  Proceed to Checkout
                </Link>
              </div>
            </div> */}
          </div>
          {/* ) : (
              <div className="row">
                <div className="col-lg-12">
                  <div className="item-empty-area text-center">
                    <div className="item-empty-area__icon mb-30">
                      <i className="pe-7s-cart"></i>
                    </div>
                    <div className="item-empty-area__text">
                      No items found in cart <br />{" "}
                      <Link to={process.env.PUBLIC_URL + "/shop"}>
                        Shop Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )} */}
        </div>
      </LayoutOne>
    </Fragment>
  );
};

Cart.propTypes = {
  addToCart: PropTypes.func,
  cartItems: PropTypes.array,
  settings: PropTypes.object,
  userDetail: PropTypes.object,
  userData: PropTypes.object,
  decreaseQuantity: PropTypes.func,
  increaseQuantity: PropTypes.func,
  deleteAllFromCart: PropTypes.func,
  deleteAllFromCartViaApi: PropTypes.func,
  deleteFromCart: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartData,
    settings: state.settingData.ecom.basic,
    themeSettings: state.settingData.ecom.theme,
    userDetail: state.userData,
    userData: state.userData,
    adminSettings: state.settingData?.["admin-panel"] || { basic: { currency_symbol: "$" } },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (item, addToast, quantityCount) => {
      dispatch(addToCart(item, addToast, quantityCount));
    },

    decreaseQuantity: (cartItems, item, addToast) => {
      dispatch(decreaseQuantity(cartItems, item, addToast));
    },
    increaseQuantity: (cartItems, item, addToast) => {
      dispatch(increaseQuantity(cartItems, item, addToast));
    },
    deleteFromCart: (cartItems, item, addToast) => {
      dispatch(deleteFromCart(cartItems, item, addToast));
    },

    deleteAllFromCart: (addToast) => {
      dispatch(deleteAllFromCart(addToast));
    },
    deleteAllFromCartViaApi: (addToast) => {
      dispatch(deleteAllFromCartViaApi(addToast));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
